import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Text, Pressable, FlatList, ActivityIndicator, TextInput, Keyboard, SafeAreaView, ScrollView } from 'react-native';
import { DrawerActions } from '@react-navigation/native';
import { Height, Width } from '../../Utility/Utility';
import MenuWhiteIcon from '../../Assets/Images/menu-white.png';
import MenuBlackIcon from '../../Assets/Images/menu-black.png';
// import UpIcon from '../../Assets/Images/up.png';
// import DownIcon from '../../Assets/Images/down.png';
// import SearchIcon from '../../Assets/Images/search-icon-white.png';
import SearchBlackIcon from '../../Assets/Images/searchBlue.png';
import { setAuthToken } from '../../Utility/SetHeader';
import { useSelector } from 'react-redux';
import addIcon from '../../Assets/Images/add-white-icon.png';
import addBlackIcon from '../../Assets/Images/addBlue.png';
import editBlack from '../../Assets/Images/editBlue.png';
import editWhite from '../../Assets/Images/editWhite.png';
import crossIcon from '../../Assets/Images/cross-black.png';
import axios from 'axios';
import { BASE_URL, SOCKET_URL } from '../../Config';
import moment from 'moment';
import RBSheet from "react-native-raw-bottom-sheet";
import AsyncStorage from '@react-native-async-storage/async-storage';
import TextTicker from 'react-native-text-ticker';
import { useTheme } from '../../Contexts/ThemeProvider';
import Checkbox from 'expo-checkbox';
import Toast from 'react-native-toast-message';
import { Audio } from 'expo-av';
// import cancelIcon from '../../Assets/Images/cancel-blue.png';
// import deleteIcon from '../../Assets/Images/delete-blue.png';
import { Image } from 'expo-image';
import Modal from 'modal-react-native-web';
import webstomp from "webstomp-client";
import { useNavigation } from '@react-navigation/native';
import Spinner from 'react-native-loading-spinner-overlay';
import { RefreshControl } from 'react-native-web-refresh-control'
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';
import SearchIcon from '../../Assets/Images/search.svg';
import AddIcon from '../../Assets/Images/add.svg';
import EditIcon from '../../Assets/Images/edit.svg';
import CrossIcon from '../../Assets/Images/cross.svg';
import HamburgerIcon from '../../Assets/Images/hamburger.svg';
import PlusIcon from '../../Assets/Images/plus.svg';
import { BLACK, DARK_GREY, DARK_RED, GREEN, GREY, LAVENDER_MIST, LIGHT_GREY, PRIMARY, RED, WHITE } from '../../Config/colors';
import UpIcon from '../../Assets/Images/upLine.svg';
import DownIcon from '../../Assets/Images/downLine.svg';
import CancelIcon from '../../Assets/Images/cancel.svg';
import DeleteIcon from '../../Assets/Images/delete.svg';
import BriefcaseIcon from '../../Assets/Images/briefcase.svg';

const MarketWatch = (props) => {
    const dispatch = useDispatch()
    const { dark, colors } = useTheme();
    const [selectedSegment, setSelectedSegment] = useState('NFO')
    const auth = useSelector(state => state.auth)
    const showWebView = useRef(false)
    const [modalVisible, setModalVisible] = useState(false);
    const [liveData, setLiveData] = useState([])
    const [isLoading, setLoading] = useState(false)
    const refRBSheet = useRef();
    const selectedIndex = useRef();
    const [lot, setLot] = useState('1')
    const [quantity, setQuantity] = useState()
    const [marketType, setMarketType] = useState('MARKET')
    const [price, setPrice] = useState('')
    const [userDetail, setUserDetail] = useState()
    const [buttonLoader, setButtonLoader] = useState(false)
    const [topMessage, setTopMessage] = useState('')
    const [showSearch, setShowSearch] = useState(false)
    const [searchText, setSearchText] = useState('')
    const selectedTrade = useRef('');
    const userId = useRef('')
    const [isEdit, setIsEdit] = useState(false);
    const [checkShowModal, setCheckShowModal] = useState(false)
    const [checkSoundAlert, setCheckSoundAlert] = useState(false)
    const niftyPosition = useRef();
    const niftyPoints = useRef();
    const sensexPosition = useRef();
    const sensexPoints = useRef();
    const live = useRef([]);
    const client = useRef();
    const [isFocused, setIsFocused] = useState(false);
    const navigation = useNavigation();
    const [isSpinner, setIsSpinner] = useState(false);
    const [selectedTag, setSelectedTag] = useState('NSE')


    const _onMenuPress = () => {
        showWebView.current = true
        props.navigation.dispatch(DrawerActions.openDrawer());
    }

    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            if (auth.access_token) {
                showWebView.current = false
                setAuthToken(auth.access_token)
                getUserDetail()
                checkTab()
                checkOtherOptions()
            }
        }
        return () => {
            isCancelled = true
        }
    }, [isFocused])

    useEffect(() => {
        Audio.setAudioModeAsync({
            staysActiveInBackground: true,
            playsInSilentModeIOS: true,
        });
    }, []);

    useEffect(() => {

        setInterval(() => {
            if (!client.current || !client.current.connected) {
                connect();
            }
        }, 3000);


        const unsubscribeFocus = navigation.addListener('focus', () => {
            setIsFocused(true);
        });
        const unsubscribeBlur = navigation.addListener('blur', () => {
            setIsFocused(false);
        });

        return () => {
            unsubscribeFocus();
            unsubscribeBlur();
        };
    }, []);



    useEffect(() => {
        connect();
    }, [isFocused])

    const connect = () => {
        var url = SOCKET_URL;
        var login = "market";
        var password = "EasyTrade";
        if (!client.current || !client.current.connected) {
            client.current = webstomp.client(url, { debug: false });
            client.current.connect(login, password, () => {
                client.current.subscribe("/exchange/market-data", (message) => {
                    onMessage(JSON.parse(message.body))
                });
            });
        }
    }

    const checkTab = async () => {
        await AsyncStorage.getItem('tab')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setSelectedSegment(data.currenTab)
                    getData(data.currenTab)
                    getUserDetail()
                    getMessage()
                } else {
                    getData('NFO')
                    getUserDetail()
                    getMessage()
                }
            })
            .catch(error => {
                getData('NFO')
                getUserDetail()
                getMessage()
            });
    }

    const checkOtherOptions = async () => {
        await AsyncStorage.getItem('TRADE')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setCheckShowModal(data)
                } else {
                }
            })
            .catch(error => {
            });
        await AsyncStorage.getItem('SOUND')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setCheckSoundAlert(data)
                } else {
                }
            })
            .catch(error => {
            });
    }

    const getUserDetail = async () => {
        await AsyncStorage.getItem('user_detail')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    userId.current = data.id
                    setUserDetail(data)
                } else {
                }
            })
            .catch(error => {
            });
    }

    const getMessage = () => {
        axios.get(`${BASE_URL}/settings`)
            .then((res) => {
                setTopMessage(res.data.header_message)
            })
            .catch((e) => {
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const getData = (value, search) => {
        setLoading(true)
        axios.get(`${BASE_URL}/wish-list-mobile?exchange=${value == 'ALL' ? '' : value}&keyword=${search ? search : ''}`)
            .then((res) => {
                for (var i in res.data) {
                    res.data[i].isChecked = false;
                }
                live.current = res.data
                setLiveData(res.data)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    var isRunning = false;
    const onMessage = (data) => {
        if (!isRunning) {
            if (live.current.length > 0 && data.length > 0) {
                for (var i in data) {
                    if (data[i].it == 265) {
                        sensexPosition.current = data[i].ltp;
                        sensexPoints.current = data[i].n;
                    }
                    if (data[i].it == 256265) {
                        niftyPosition.current = data[i].ltp;
                        niftyPoints.current = data[i].n;
                    }
                    for (var j in live.current) {
                        if (live.current[j].instrument_token == data[i].it) {
                            live.current[j].a = data[i].a.toFixed(2);
                            live.current[j].b = data[i].b.toFixed(2);
                            live.current[j].ac = data[i].ac;
                            live.current[j].bc = data[i].bc;
                            live.current[j].ltpc = data[i].ltpc;
                            live.current[j].c = data[i].c;
                            live.current[j].ltp = data[i].ltp.toFixed(2);
                            live.current[j].it = data[i].it;
                            live.current[j].ch = data[i].ch;
                            live.current[j].h = data[i].h.toFixed(2);
                            live.current[j].l = data[i].l.toFixed(2);
                            live.current[j].o = data[i].o;
                            live.current[j].n = data[i].n;
                            live.current[j].ul = data[i].ul;
                            live.current[j].ll = data[i].ll;
                            live.current[j].ba = data[i].ba;
                        }
                    }
                }

                setLiveData([...live.current])
            }

            isRunning = false;
        }

    }

    const onSubmit = (status) => {
        if (buttonLoader || isSpinner) {
            return;
        }
        setButtonLoader(true)
        setIsSpinner(true)
        setModalVisible(false)
        var priceValue = '';
        var reject_reason = '';
        if (status == 'BUY' && marketType == 'MARKET') {
            priceValue = liveData[selectedIndex.current].a
        } else if (status == 'SELL' && marketType == 'MARKET') {
            priceValue = liveData[selectedIndex.current].b
        } else if (marketType == 'LIMIT' || marketType == 'STOPLOSS') {
            if (price == 0 || price == '') {
                Toast.show({
                    type: 'error',
                    text1: `Price can't be zero(0).`,
                    visibilityTime: 1000
                });
                return;
            }

            priceValue = price
        }

        // if (
        //     Number(price) < Number(liveData[selectedIndex.current].ll) &&
        //     marketType != "MARKET" && marketType != "INTRADAY"
        // ) {
        //     Toast.show({
        //         type: 'error',
        //         text1: `price should be greater then lower limit`,
        //         text2: `${liveData[selectedIndex.current].ll}. `,
        //         visibilityTime: 5000
        //     });
        //     var reject_reason =
        //         "price should be greater then lower limit " +
        //         liveData[selectedIndex.current].ll +
        //         ".";
        // }

        // if (Number(price) > Number(liveData[selectedIndex.current].ul) && marketType != "INTRADAY") {
        //     Toast.show({
        //         type: 'error',
        //         text1: `price should be less then upper limit`,
        //         text2: `${liveData[selectedIndex.current].ul}. `,
        //     });
        //     var reject_reason =
        //         "price should be less then upper limit " +
        //         liveData[selectedIndex.current].ul +
        //         ".";
        // }


        // if (
        //     status == "BUY" &&
        //     marketType == "STOPLOSS"
        // ) {
        //     if (
        //         price <= liveData[selectedIndex.current].ltp &&
        //         price <= liveData[selectedIndex.current].a
        //     ) {
        //         Toast.show({
        //             type: 'error',
        //             text1: `Stoploss price should be greater then ltp price.`,
        //         });
        //         var reject_reason =
        //             "Stoploss price should be greater then ltp price.";
        //     }
        // }

        // if (
        //     status == "SELL" &&
        //     marketType == "STOPLOSS"
        // ) {
        //     if (
        //         price >= liveData[selectedIndex.current].ltp &&
        //         price >= liveData[selectedIndex.current].b
        //     ) {
        //         Toast.show({
        //             type: 'error',
        //             text1: `Stoploss price should be less then ltp price.`,
        //         });
        //         var reject_reason =
        //             "Stoploss price should be less then ltp price.";
        //     }
        // }


        // if (marketType == "LIMIT") {
        //     if (
        //         price >= liveData[selectedIndex.current].b &&
        //         price <= liveData[selectedIndex.current].a
        //     ) {
        //         Toast.show({
        //             type: 'error',
        //             text1: `Limit price should not be in between bid price and ask price.`,
        //         });
        //         var reject_reason =
        //             "Limit price should not be in between bid price and ask price.";
        //     }
        // }

        // if (
        //     marketType == "LIMIT" &&
        //     status == "BUY"
        // ) {
        //     if (price > liveData[selectedIndex.current].ltp) {
        //         Toast.show({
        //             type: 'error',
        //             text1: `Limit price should be Less then LTP.`,
        //         });
        //         var reject_reason = "Limit price should be Less then LTP.";
        //     }
        // }


        // if (
        //     marketType == "LIMIT" &&
        //     status == "SELL"
        // ) {
        //     if (price < liveData[selectedIndex.current].ltp) {
        //         Toast.show({
        //             type: 'error',
        //             text1: `Limit price should be greater then LTP.`,
        //         });
        //         var reject_reason = "Limit price should be greater then LTP.";
        //     }
        // }

        if (marketType == 'LIMIT' || marketType == 'STOPLOSS') {
            var data = (priceValue / liveData[selectedIndex.current]?.tick_size).toFixed(2) % 1 == 0
            if (priceValue == '' || data == false) {
                if (priceValue == '') {
                    Toast.show({
                        type: 'error',
                        text1: "Please enter price.",
                        visibilityTime: 1000
                    });
                } else if (data == false) {
                    Toast.show({
                        type: 'error',
                        text1: ` Price Should be in multiple of ${liveData[selectedIndex.current]?.tick_size}`,
                        visibilityTime: 1000
                    });
                }

            } else {
                setButtonLoader(true)
                setIsSpinner(true)
                axios.post(`${BASE_URL}/add-trade`, {
                    a: liveData[selectedIndex.current].a,
                    ac: liveData[selectedIndex.current].ac,
                    b: liveData[selectedIndex.current].b,
                    bc: liveData[selectedIndex.current].bc,
                    c: liveData[selectedIndex.current].c,
                    ch: liveData[selectedIndex.current].ch,
                    device: 'MOBILE',
                    exchange: liveData[selectedIndex.current].exchange,
                    h: liveData[selectedIndex.current].h,
                    instrument_token: liveData[selectedIndex.current].instrument_token,
                    l: liveData[selectedIndex.current].l,
                    lot_size: lot,
                    ltp: liveData[selectedIndex.current].ltp,
                    ltpc: liveData[selectedIndex.current].ltpc,
                    n: liveData[selectedIndex.current].n,
                    name: liveData[selectedIndex.current].name,
                    o: liveData[selectedIndex.current].o,
                    order_action: status,
                    order_type: marketType,
                    price: priceValue,
                    quantity: quantity,
                    trading_symbol: liveData[selectedIndex.current].name + " " + moment(liveData[selectedIndex.current].expiry).format('DDMMM').toUpperCase(),
                    user_id: userDetail.id,
                    reject_reason: reject_reason ? reject_reason : '',
                    ba: liveData[selectedIndex.current].ba
                })
                    .then(async (res) => {
                        setIsSpinner(false)
                        console.log("res1", res.data)
                        setButtonLoader(false)
                        setModalVisible(false)
                        if (reject_reason) {
                            if (checkSoundAlert) {
                                onPlayErrorSound()
                            }
                        } else {
                            if (status == 'SELL') {
                                Toast.show({
                                    type: 'error',
                                    text1: marketType == 'INTRADAY' ? 'Intraday Close Limit Order Placed Sucessfully' : `${marketType == 'MARKET' ? 'Trade' : 'Order'} Placed Sucessfully`,
                                    text2: marketType != 'INTRADAY' && `${liveData[selectedIndex.current].name} ${liveData[selectedIndex.current].expiry && moment(liveData[selectedIndex.current].expiry).format('DDMMM').toUpperCase()} ${status} ${quantity} @ ${res?.data?.trade_obj?.price ? res?.data?.trade_obj?.price : priceValue}. `,
                                    visibilityTime: 1000
                                });
                            } else {
                                Toast.show({
                                    type: 'success',
                                    text1: marketType == 'INTRADAY' ? 'Intraday Close Limit Order Placed Sucessfully' : `${marketType == 'MARKET' ? 'Trade' : 'Order'} Placed Sucessfully`,
                                    text2: marketType != 'INTRADAY' && `${liveData[selectedIndex.current].name} ${liveData[selectedIndex.current].expiry && moment(liveData[selectedIndex.current].expiry).format('DDMMM').toUpperCase()} ${status} ${quantity} @ ${res?.data?.trade_obj?.price ? res?.data?.trade_obj?.price : priceValue}. `,
                                    visibilityTime: 1000
                                });
                            }

                            if (checkSoundAlert) {
                                onPlaySuccessSound()
                            }
                        }

                        setMarketType('MARKET')
                        setPrice('')
                        setQuantity('')
                        setLot('')
                        getData(selectedSegment)
                    })
                    .catch(async (e) => {
                        setIsSpinner(false)
                        setModalVisible(false)
                        setButtonLoader(false)
                        console.log("e?.response?.data?.message", e?.response?.data?.message)
                        Toast.show({
                            type: 'error',
                            text1: e?.response?.data?.message,
                            visibilityTime: 1000
                        });
                        if (checkSoundAlert) {
                            onPlayErrorSound()
                        }
                        if (e.response.status == 401) {
                            dispatch(resetAuth())
                        }
                    })
            }
        } else {
            setButtonLoader(true)
            setIsSpinner(true)
            axios.post(`${BASE_URL}/add-trade`, {
                a: liveData[selectedIndex.current].a,
                ac: liveData[selectedIndex.current].ac,
                b: liveData[selectedIndex.current].b,
                bc: liveData[selectedIndex.current].bc,
                c: liveData[selectedIndex.current].c,
                ch: liveData[selectedIndex.current].ch,
                device: 'MOBILE',
                exchange: liveData[selectedIndex.current].exchange,
                h: liveData[selectedIndex.current].h,
                instrument_token: liveData[selectedIndex.current].instrument_token,
                l: liveData[selectedIndex.current].l,
                lot_size: lot,
                ltp: liveData[selectedIndex.current].ltp,
                ltpc: liveData[selectedIndex.current].ltpc,
                n: liveData[selectedIndex.current].n,
                name: liveData[selectedIndex.current].name,
                o: liveData[selectedIndex.current].o,
                order_action: status,
                order_type: marketType,
                price: priceValue,
                quantity: quantity,
                trading_symbol: liveData[selectedIndex.current].name + " " + moment(liveData[selectedIndex.current].expiry).format('DDMMM').toUpperCase(),
                user_id: userDetail.id,
                reject_reason: reject_reason ? reject_reason : '',
                ba: liveData[selectedIndex.current].ba
            })
                .then(async (res) => {
                    setIsSpinner(false)
                    refRBSheet.current.close()
                    setMarketType('MARKET')
                    setPrice('')
                    setQuantity('')
                    setLot('')
                    setButtonLoader(false)
                    setModalVisible(false)
                    if (reject_reason) {
                        if (checkSoundAlert) {
                            onPlayErrorSound()
                        }
                    } else {
                        if (status == 'SELL') {
                            Toast.show({
                                type: 'error',
                                text1: marketType == 'INTRADAY' ? 'Intraday Close Limit Order Placed Sucessfully' : `${marketType == 'MARKET' ? 'Trade' : 'Order'} Placed Sucessfully`,
                                text2: marketType != 'INTRADAY' && `${liveData[selectedIndex.current].name} ${liveData[selectedIndex.current].expiry && moment(liveData[selectedIndex.current].expiry).format('DDMMMYYYY').toUpperCase()} ${status} ${quantity} @ ${res?.data?.trade_obj?.price ? res?.data?.trade_obj?.price : priceValue}. `,
                                visibilityTime: 1000
                            });
                        } else {
                            Toast.show({
                                type: 'success',
                                text1: marketType == 'INTRADAY' ? 'Intraday Close Limit Order Placed Sucessfully' : `${marketType == 'MARKET' ? 'Trade' : 'Order'} Placed Sucessfully`,
                                text2: marketType != 'INTRADAY' && `${liveData[selectedIndex.current].name} ${liveData[selectedIndex.current].expiry && moment(liveData[selectedIndex.current].expiry).format('DDMMMYYYY').toUpperCase()} ${status} ${quantity} @ ${res?.data?.trade_obj?.price ? res?.data?.trade_obj?.price : priceValue}. `,
                                visibilityTime: 1000
                            });
                        }

                        if (checkSoundAlert) {
                            onPlaySuccessSound()
                        }
                    }
                    getData(selectedSegment)
                })
                .catch(async (e) => {
                    setIsSpinner(false)
                    setModalVisible(false)
                    setButtonLoader(false)
                    console.log("e?.response?.data?.message", e?.response?.data?.message)
                    Toast.show({
                        type: 'error',
                        text1: e?.response?.data?.message,
                        visibilityTime: 1000
                    });
                    if (checkSoundAlert) {
                        onPlayErrorSound()
                    }
                    if (e.response.status == 401) {
                        dispatch(resetAuth())
                    }
                })
        }
    }

    const onChangingLot = (value) => {
        setLot(value)
        setQuantity((value * liveData[selectedIndex.current].lot_size).toString())
    }

    const onChangingQuantity = (value) => {
        setQuantity(value.replace(/[^0-9]/g, ''))
        var data = (1 / liveData[selectedIndex.current].lot_size * value)
        setLot(data.toFixed(2))
    }

    const onChangeTab = async (value) => {
        const data = JSON.stringify({ currenTab: value })
        await AsyncStorage.setItem('tab', data)
        setSelectedSegment(value)
        getData(value)
    }

    const onDeleteScript = () => {
        var array = [];
        for (var i in liveData) {
            if (liveData[i].isChecked == true) {
                array.push(liveData[i].id)
            }
        }
        setIsSpinner(true)
        axios.put(`${BASE_URL}/remove-from-wishlist-app`, {
            ids: array
        })
            .then((res) => {
                setIsEdit(false)
                setIsSpinner(false)
                checkTab()
            })
            .catch((e) => {
                setIsSpinner(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const onPlaySuccessSound = async () => {
        const { sound } = await Audio.Sound.createAsync(
            require('../../Assets/sound/login_sound.mp3'),
            undefined,
            null,
            false
        );
        await sound.playAsync();
        setTimeout(() => {
            sound.unloadAsync();
        }, 10000)
    }

    const onPlayErrorSound = async () => {
        const { sound } = await Audio.Sound.createAsync(
            require('../../Assets/sound/errorNew.mp3'),
            undefined,
            null,
            false
        );
        await sound.playAsync();
        setTimeout(() => {
            sound.unloadAsync();
        }, 10000)
    }

    return (
        <>
            <Spinner
                visible={isSpinner}
                textContent={'Loading...'}
                textStyle={styles.spinnerTextStyle}
            />
            <SafeAreaView style={styles.container}>
                <View style={styles.topView}>
                    <View style={styles.nameView}>
                        <Pressable onPress={() => _onMenuPress()} style={styles.profileBackIcon}>
                            <HamburgerIcon style={styles.hamburgerIcon} />
                        </Pressable>
                        <Text style={styles.name}>Hi, {global.name}</Text>
                    </View>
                    <Pressable style={styles.addBackground} onPress={() => {
                        showWebView.current = true
                        props.navigation.navigate('Scripts')
                    }}>
                        <PlusIcon style={styles.plusIcon} />
                    </Pressable>
                </View>
                <View style={styles.searchBox}>
                    <TextInput
                        placeholder="Search script"
                        placeholderTextColor={BLACK}
                        style={styles.search}
                        value={searchText}
                        onChangeText={text => { setSearchText(text), getData(selectedSegment, text) }} />
                </View>
                <View style={styles.centerView}>
                    <View style={styles.tagView}>
                        <Pressable style={[styles.tag, {
                            backgroundColor: selectedSegment == "ALL" ? PRIMARY : LAVENDER_MIST,
                            marginLeft: 17
                        }]} onPress={() => onChangeTab('ALL')} hitSlop={styles.hit}>
                            <Text style={[styles.tagText, {
                                color: selectedSegment == "ALL" ? WHITE : PRIMARY,
                            }]}>All</Text>
                        </Pressable>
                        <Pressable style={[styles.tag, {
                            backgroundColor: selectedSegment == "NFO" ? PRIMARY : LAVENDER_MIST,
                            marginLeft: 17
                        }]} onPress={() => onChangeTab('NFO')} hitSlop={styles.hit}>
                            <Text style={[styles.tagText, {
                                color: selectedSegment == "NFO" ? WHITE : PRIMARY,
                            }]}>NSE</Text>
                        </Pressable>
                        <Pressable style={[styles.tag, {
                            backgroundColor: selectedSegment == "MCX" ? PRIMARY : LAVENDER_MIST,
                            marginLeft: 17
                        }]} onPress={() => onChangeTab('MCX')} hitSlop={styles.hit}>
                            <Text style={[styles.tagText, {
                                color: selectedSegment == "MCX" ? WHITE : PRIMARY,
                            }]}>MCX</Text>
                        </Pressable>
                    </View>
                    <View style={styles.deleteIconsView}>
                        {
                            !isEdit ?
                                <Pressable onPress={() => setIsEdit(!isEdit)} hitSlop={styles.hit}>
                                    <EditIcon fill={PRIMARY} height={20} width={20} />
                                </Pressable>
                                :
                                <View style={styles.deleteView}>
                                    <Pressable onPress={() => setIsEdit(false)} hitSlop={styles.hit}>
                                        <CancelIcon fill={PRIMARY} />
                                    </Pressable>
                                    <Pressable onPress={() => onDeleteScript()} hitSlop={styles.hit}>
                                        <DeleteIcon fill={PRIMARY} />
                                    </Pressable>
                                </View>
                        }
                    </View>
                </View>
                {isLoading && liveData.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color={BLACK} />
                </View>}
                {!isLoading && liveData.length == 0 && <View style={styles.loaderView}>
                    <Text style={styles.errorMessage}>No Scripts Found!</Text>
                </View>}
                <ScrollView>
                    {!isLoading && liveData.length > 0 &&
                        <View style={{ height: Height * 0.7 }}>
                            <FlatList
                                data={liveData}
                                renderItem={({ item, index }) =>
                                    <View style={styles.item}>
                                        {
                                            isEdit &&
                                            <View style={styles.checkBoxView}>
                                                <Checkbox
                                                    disabled={false}
                                                    value={item.isChecked}
                                                    onValueChange={(newValue) => {
                                                        liveData[index].isChecked = !liveData[index].isChecked
                                                        setLiveData([...liveData])
                                                    }}
                                                    color={item.isChecked ? PRIMARY : BLACK}
                                                    hitSlop={styles.hit}
                                                />
                                            </View>
                                        }
                                        <Pressable style={[styles.card, { width: isEdit ? '80%' : '95%', marginTop: index == 0 ? 20 : 8, backgroundColor: item.ba ? 'orange' : WHITE }]} onPress={() => { isEdit == false && refRBSheet.current.open(), selectedIndex.current = index, setQuantity(item.lot_size.toString()), setLot('1'), setPrice(''), setMarketType('MARKET') }}>
                                            <View>
                                                {
                                                    // !isEdit &&
                                                    <View style={styles.percentageView}>
                                                        <Text style={[styles.percentage, { color: item.n > 0 ? GREEN : RED }]}>{item.n && item.n.toFixed(2)} ({item.ch && item.ch.toFixed(2)}%)</Text>
                                                        {item.n > 0 ? <UpIcon style={styles.upDownIcon} /> : <DownIcon style={styles.upDownIcon} />}
                                                    </View>
                                                }
                                                <Text style={styles.cardTitle}>{item.name}-{item.expiry && `${moment(item.expiry).format('MMM').toUpperCase()}`}</Text>
                                                <View style={{ flexDirection: 'row', marginLeft: 8, marginBottom: 8 }}>
                                                    <Text style={styles.expiry}>{item.expire_new && `${moment(item.expire_new).format('DDMMM')}`}</Text>
                                                    {Number(item.total_buy_quantity) - Number(item.total_sell_quantity) != 0 && <View style={styles.quantityView}>
                                                        <BriefcaseIcon />
                                                        <Text style={{ color: (Number(item.total_buy_quantity) - Number(item.total_sell_quantity) > 0 ? 'green' : (Number(item.total_buy_quantity) - Number(item.total_sell_quantity)) == 0 ? 'black' : 'red'), fontFamily: 'roboto-medium', fontSize: 11 }}>{Number(item.total_buy_quantity) - Number(item.total_sell_quantity) > 0 ? "+" : ''} {Number(item.total_buy_quantity) - Number(item.total_sell_quantity)}</Text>
                                                    </View>}
                                                </View>
                                            </View>
                                            <View style={{ marginRight: 30, marginTop: 5 }}>
                                                {
                                                    !isEdit &&
                                                    <View style={styles.priceView}>
                                                        <View style={[styles.priceTag, {
                                                            backgroundColor: item.bc == 'black' ? WHITE : item.bc,
                                                        }]}>
                                                            <Text style={[styles.price, { color: (item.bc == 'black' && item.b) ? BLACK : (item.bc && item.b) ? WHITE : BLACK }]}>{item.b}</Text>
                                                        </View>
                                                        <View style={[styles.priceTag, {
                                                            backgroundColor: item.ac == 'black' ? WHITE : item.ac,
                                                            marginLeft: 10
                                                        }]}>
                                                            <Text style={[styles.price, { color: (item.ac == 'black' && item.a) ? 'black' : (item.ac && item.a) ? 'white' : 'black' }]}>{item.a}</Text>
                                                        </View>
                                                    </View>
                                                }
                                                {!isEdit && <Text style={styles.hl}>L: {item.l}   H: {item.h}</Text>}
                                            </View>
                                        </Pressable>
                                    </View>
                                }
                                keyExtractor={item => item.id}
                                refreshControl={
                                    <RefreshControl refreshing={isLoading} enabled={true} onRefresh={() => getData(selectedSegment)} />
                                }
                            />
                        </View>
                    }
                </ScrollView>
                <RBSheet
                    ref={refRBSheet}
                    closeOnDragDown={true}
                    closeOnPressMask={true}
                    height={310}
                    customStyles={{
                        wrapper: {
                            backgroundColor: "transparent",
                        },
                        draggableIcon: {
                            backgroundColor: "#000",
                        },
                        container: {
                            borderTopRightRadius: 30,
                            borderTopLeftRadius: 30,
                            backgroundColor: PRIMARY
                        }
                    }}
                >
                    <View style={styles.sheetTopView}>
                        <View style={styles.sheetNameView}>
                            <Text style={styles.sheetName}>{liveData[selectedIndex.current]?.name}</Text>
                            <Text style={styles.sheetLtp}>{liveData[selectedIndex.current]?.ltp}</Text>
                        </View>
                        <View style={styles.sheetExpiryView}>
                            <Text style={styles.sheetExpiry}>{moment(liveData[selectedIndex.current]?.expiry).format('DDMMMYYYY')}</Text>
                            <View style={styles.iconPercentageView}>
                                <Text style={[styles.sheetPercentage, { color: liveData[selectedIndex.current]?.n > 0 ? GREEN : RED }]}>{liveData[selectedIndex.current]?.n && liveData[selectedIndex.current]?.n.toFixed(2)} ({liveData[selectedIndex.current]?.ch && liveData[selectedIndex.current]?.ch.toFixed(2)}%)</Text>
                                {liveData[selectedIndex.current]?.n > 0 ? <UpIcon style={styles.upDownIcon} /> : <DownIcon style={styles.upDownIcon} />}
                            </View>
                        </View>
                    </View>
                    <View style={styles.sheetMainView}>
                        <View style={styles.ohlcView}>
                            <View>
                                <Text style={styles.openTitle}>Open</Text>
                                <Text style={styles.openPrice}>{liveData[selectedIndex.current]?.o}</Text>
                            </View>
                            <View>
                                <Text style={styles.openTitle}>High</Text>
                                <Text style={styles.openPrice}>{liveData[selectedIndex.current]?.h}</Text>
                            </View>
                            <View>
                                <Text style={styles.openTitle}>Low</Text>
                                <Text style={styles.openPrice}>{liveData[selectedIndex.current]?.l}</Text>
                            </View>
                            <View>
                                <Text style={styles.openTitle}>Close</Text>
                                <Text style={styles.openPrice}>{liveData[selectedIndex.current]?.c}</Text>
                            </View>
                        </View>
                        <View style={styles.marketTypeView}>
                            <Pressable style={[styles.marketType, { backgroundColor: marketType == 'MARKET' ? PRIMARY : LAVENDER_MIST }]} onPress={() => setMarketType('MARKET')}>
                                <Text style={[styles.marketTypeText, { color: marketType == 'MARKET' ? WHITE : PRIMARY }]}>MARKET</Text>
                            </Pressable>
                            <Pressable style={[styles.marketType, { backgroundColor: marketType == 'LIMIT' ? PRIMARY : LAVENDER_MIST }]} onPress={() => setMarketType('LIMIT')}>
                                <Text style={[styles.marketTypeText, { color: marketType == 'LIMIT' ? WHITE : PRIMARY }]}>LIMIT</Text>
                            </Pressable>
                            <Pressable style={[styles.marketType, { backgroundColor: marketType == 'STOPLOSS' ? PRIMARY : LAVENDER_MIST }]} onPress={() => setMarketType('STOPLOSS')}>
                                <Text style={[styles.marketTypeText, { color: marketType == 'STOPLOSS' ? WHITE : PRIMARY }]}>SL</Text>
                            </Pressable>
                            <Pressable style={[styles.marketType, { backgroundColor: marketType == 'INTRADAY' ? PRIMARY : LAVENDER_MIST }]} onPress={() => setMarketType('INTRADAY')}>
                                <Text style={[styles.marketTypeText, { color: marketType == 'INTRADAY' ? WHITE : PRIMARY }]}>INTRADAY</Text>
                            </Pressable>
                        </View>
                        {
                            marketType != 'INTRADAY' &&
                            <View style={styles.inputView}>
                                <View>
                                    <Text style={styles.label}>Lot</Text>
                                    <TextInput
                                        style={styles.inputStyle}
                                        keyboardType='numeric'
                                        value={lot}
                                        onChangeText={text => onChangingLot(text.replace(/[^0-9]/g, ''))}
                                    />
                                </View>
                                <View>
                                    <Text style={styles.label}>Quantity</Text>
                                    <TextInput
                                        style={styles.inputStyle}
                                        keyboardType='numeric'
                                        editable={selectedSegment == 'NFO' ? true : false}
                                        value={quantity}
                                        onChangeText={text => onChangingQuantity(text)}
                                    />
                                </View>
                                {
                                    (marketType == 'LIMIT' || marketType == 'STOPLOSS') &&
                                    <View>
                                        <Text style={styles.label}>Price</Text>
                                        <TextInput
                                            style={styles.inputStyle}
                                            keyboardType='decimal-pad'
                                            value={price}
                                            onChangeText={text => {
                                                setPrice(text)
                                                var data = (text / liveData[selectedIndex.current].tick_size).toFixed(2) % 1 == 0
                                                {
                                                    data == false &&
                                                        Toast.show({
                                                            type: 'error',
                                                            text1: `Price Should be in multiple of ${liveData[selectedIndex.current]?.tick_size}`,
                                                            visibilityTime: 1000
                                                        });
                                                }
                                            }}
                                        />
                                    </View>
                                }
                            </View>
                        }
                        {
                            marketType != 'INTRADAY' &&
                            <View style={styles.buttonView}>
                                <Pressable style={[styles.button, { backgroundColor: DARK_RED }]}
                                    disabled={buttonLoader}
                                    onPress={() => {
                                        if (checkShowModal) {
                                            selectedTrade.current = 'SELL'
                                            Keyboard.dismiss()
                                            refRBSheet.current.close()
                                            onSubmit('SELL')
                                        } else {
                                            selectedTrade.current = 'SELL'
                                            Keyboard.dismiss()
                                            refRBSheet.current.close()
                                            setModalVisible('true')
                                        }
                                    }}>
                                    {buttonLoader && <ActivityIndicator size="small" color={WHITE} style={styles.buttonLoader} />}
                                    <Text style={[styles.buttonText, { marginLeft: buttonLoader ? 10 : 16 }]}>{(marketType == 'LIMIT' || marketType == 'STOPLOSS') ? 'SELL' : `SELL @ ${liveData[selectedIndex.current]?.b}`}</Text>
                                </Pressable>
                                <Pressable style={[styles.button, { backgroundColor: PRIMARY }]}
                                    disabled={buttonLoader}
                                    onPress={() => {
                                        if (checkShowModal) {
                                            selectedTrade.current = 'BUY'
                                            Keyboard.dismiss()
                                            refRBSheet.current.close()
                                            onSubmit('BUY')
                                        } else {
                                            selectedTrade.current = 'BUY'
                                            Keyboard.dismiss()
                                            refRBSheet.current.close()
                                            setModalVisible('true')
                                        }

                                    }}>
                                    {buttonLoader && <ActivityIndicator size="small" color={WHITE} style={styles.buttonLoader} />}
                                    <Text style={[styles.buttonText, { marginLeft: buttonLoader ? 10 : 16 }]}>{(marketType == 'LIMIT' || marketType == 'STOPLOSS') ? 'BUY' : `BUY @ ${liveData[selectedIndex.current]?.a}`}</Text>
                                </Pressable>
                            </View>
                        }
                        {
                            marketType == 'INTRADAY' &&
                            <View style={styles.buttonView}>
                                <Pressable style={[styles.button, { backgroundColor: PRIMARY }]}
                                    disabled={buttonLoader}
                                    onPress={() => {
                                        if (checkShowModal) {
                                            selectedTrade.current = 'BUY'
                                            Keyboard.dismiss()
                                            onSubmit('BUY')
                                        } else {
                                            selectedTrade.current = 'BUY'
                                            Keyboard.dismiss()
                                            refRBSheet.current.close()
                                            setModalVisible('true')
                                        }
                                    }}>
                                    {buttonLoader && <ActivityIndicator size="small" color={WHITE} style={styles.buttonLoader} />}
                                    <Text style={[styles.buttonText, { marginLeft: buttonLoader ? 10 : 16 }]}>Submit</Text>
                                </Pressable>
                            </View>
                        }
                    </View>
                </RBSheet>
                <Modal animationType="slide" transparent={true} visible={modalVisible} ariaHideApp={false}>
                    <View style={styles.centeredView}>
                        <View style={styles.modalView}>
                            <View >
                                <View style={{ backgroundColor: 'white', borderRadius: 20 }}>
                                    <Text style={{ textAlign: 'center', fontSize: 15, color: colors.Secondary, fontWeight: '600', marginTop: 20 }}>Are you sure you want to place order.</Text>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                        <Pressable style={styles.cancelButton} disabled={isSpinner} onPress={() => {
                                            refRBSheet.current.open()
                                            setModalVisible(!modalVisible)
                                        }}>
                                            <Text style={styles.cancelText}>No</Text>
                                        </Pressable>
                                        <Pressable onPress={() => onSubmit(selectedTrade.current)} disabled={isSpinner} style={[styles.continueButton, { backgroundColor: selectedTrade.current == 'BUY' ? PRIMARY : DARK_RED }]}>
                                            <Text style={styles.continueText}>{marketType == 'INTRADAY' ? 'Yes' : selectedTrade.current}</Text>
                                        </Pressable>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </Modal>
            </SafeAreaView>
            <View style={{ backgroundColor: PRIMARY }}>
                <TextTicker
                    style={{ fontSize: 12, color: RED, marginTop: 5, height: 20, fontFamily: 'Roboto-Bold' }}
                    duration={10000}
                    loop
                    bounce
                    repeatSpacer={50}
                    marqueeDelay={1000}
                    shouldAnimateTreshold={80}
                >
                    {topMessage}
                </TextTicker>
            </View>
        </>
    )
}

export default MarketWatch;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: WHITE
    },
    profileBackIcon: {
        marginLeft: 20,
        backgroundColor: LIGHT_GREY,
        borderRadius: 8,
        padding: 13,
        marginTop: 10
    },
    topView: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    nameView: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    name: {
        color: BLACK,
        fontFamily: 'Roboto-Medium',
        fontSize: 14,
        marginLeft: 17,
        marginTop: 10
    },
    addBackground: {
        marginTop: 12,
        marginRight: 20,
        backgroundColor: PRIMARY,
        height: 35,
        width: 35,
        borderRadius: 35 / 2,
        justifyContent: 'center',
        alignItems: 'center'
    },
    plusIcon: {
    },
    hamburgerIcon: {
        height: 20,
        width: 20
    },
    searchBox: {
        backgroundColor: GREY,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 15,
        borderRadius: 53,
        flexDirection: 'row',
        height: 45
    },
    search: {
        paddingLeft: 28,
        width: '100%'
    },
    searchButton: {
        backgroundColor: PRIMARY,
        borderRadius: 32,
        alignItems: 'center',
        height: 38,
        marginTop: 10
    },
    searchButtonText: {
        color: WHITE,
        fontSize: 14,
        fontFamily: 'Roboto-Regular',
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 10
    },
    tagView: {
        flexDirection: 'row'
    },
    tag: {
        borderRadius: 35,
        marginTop: 25
    },
    tagText: {
        fontFamily: 'Roboto-Medium',
        fontSize: 11,
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 16,
        paddingLeft: 16
    },
    hit: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20
    },
    card: {
        borderWidth: 1,
        borderColor: GREY,
        marginLeft: 10,
        marginRight: 10,
        marginTop: 8,
        borderRadius: 12,
        shadowOpacity: 0.26,
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 10,
        elevation: 4,
        backgroundColor: WHITE,
        shadowColor: 'rgba(0, 0, 0, 0.25)',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    cardTitle: {
        color: BLACK,
        fontFamily: 'Roboto-Medium',
        // marginTop: 12,
        marginLeft: 8,
        fontSize: 16
    },
    cardTopView: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: 20
    },
    priceView: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 12,
    },
    priceTag: {
        borderRadius: 5,
        width: '50%',
        alignItems: 'center',
    },
    price: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 5,
        paddingRight: 5,
        color: WHITE,
        fontFamily: 'Roboto-Bold',
        fontSize: 18
    },
    cardBottomView: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 2,
        marginBottom: 6,
        justifyContent: 'space-between'
    },
    expiry: {
        color: BLACK,
        // marginLeft: 16,
        fontFamily: 'Roboto-Bold',
        fontSize: 12
    },
    percentage: {
        color: GREEN,
        fontFamily: 'Roboto-Bold',
        fontSize: 11
    },
    upDownIcon: {
        marginRight: 20,
        marginLeft: 1
    },
    percentageView: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 10,
        marginLeft: 8
    },
    loaderView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    errorMessage: {
        fontSize: 14,
        fontFamily: 'Roboto-Bold'
    },
    sheetTopView: {
        backgroundColor: PRIMARY,
    },
    sheetNameView: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    sheetName: {
        color: WHITE,
        fontFamily: 'Roboto-Medium',
        fontSize: 22,
        marginLeft: 15,
        // marginTop: 5
    },
    sheetLtp: {
        color: WHITE,
        marginRight: 15,
        fontFamily: 'Roboto-Bold',
        fontSize: 20,
        // marginTop: 5
    },
    sheetExpiry: {
        color: DARK_GREY,
        marginLeft: 15,
        marginTop: 4,
        fontSize: 15,
        fontFamily: 'Roboto-Bold'
    },
    sheetMainView: {
        backgroundColor: WHITE,
        marginTop: 7,
        height: '100%'
    },
    sheetExpiryView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 4,
    },
    sheetPercentage: {
        fontFamily: 'Roboto-Bold',
        fontSize: 15
    },
    iconPercentageView: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    ohlcView: {
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    openTitle: {
        color: BLACK,
        fontSize: 12,
        fontFamily: 'Roboto-Medium',
        marginTop: 12,
        textAlign: 'center'
    },
    openPrice: {
        color: BLACK,
        fontSize: 12,
        fontFamily: 'Roboto-Bold',
        marginTop: 8
    },
    marketTypeView: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: 10
    },
    marketType: {
        width: 75,
        alignItems: 'center',
        borderRadius: 35
    },
    marketTypeText: {
        fontSize: 11,
        fontFamily: 'Roboto-Medium',
        marginTop: 7,
        marginBottom: 7
    },
    inputView: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: 10
    },
    label: {
        color: BLACK,
        fontSize: 14,
        fontFamily: 'Roboto-Medium'
    },
    inputStyle: {
        borderWidth: 1,
        borderRadius: 10,
        height: 40,
        borderColor: PRIMARY,
        width: 95,
        marginTop: 4,
        color: BLACK,
        paddingLeft: 5,
        fontSize: 18,
        fontFamily: 'Roboto-Bold'
    },
    buttonView: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: 10
    },
    button: {
        borderRadius: 16,
        minWidth: 100,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    buttonText: {
        color: WHITE,
        marginTop: 15,
        marginBottom: 14,
        fontSize: 14,
        fontFamily: 'Roboto-Medium',
        marginLeft: 16,
        marginRight: 16
    },
    buttonLoader: {
        marginLeft: 14
    },
    centerView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    deleteIconsView: {
        marginTop: 28,
        marginRight: 20
    },
    deleteView: {
        flexDirection: 'row'
    },
    item: {
        flexDirection: 'row'
    },
    checkBoxView: {
        marginTop: 40,
        marginLeft: 20
    },
    hit: {
        top: 10,
        bottom: 10,
        left: 0,
        right: 0
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    cancelButton: {
        borderRadius: 5,
        borderWidth: 1,
        marginTop: 20
    },
    cancelText: {
        fontSize: 16,
        textAlign: 'center',
        padding: 8,
        backgroundColor: 'transparent',
        marginLeft: 30,
        marginRight: 30
    },
    continueButton: {
        borderRadius: 5,
        marginTop: 20
    },
    continueText: {
        fontSize: 16,
        textAlign: 'center',
        color: 'white',
        padding: 8,
        backgroundColor: 'transparent',
        marginLeft: 30,
        marginRight: 30,
        fontFamily: 'Roboto-Medium'
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 10,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 500,
    },
    hl: {
        color: BLACK,
        // marginLeft: 10,
        fontFamily: 'Roboto-Medium',
        fontSize: 13,
        textAlign: 'right',
        left: 10
    },
    quantityView: {
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 8,
        // paddingTop: 3,
        // marginBottom: 4
    }
})