import React, { useEffect, useState, useRef } from 'react';
import { StyleSheet, View, Pressable, Text, FlatList, ActivityIndicator, TextInput, Modal, SafeAreaView } from 'react-native';
import { Primary, Secondary } from '../../Utility/Utility';
import MenuWhiteIcon from '../../Assets/Images/menu-white.png';
import MenuBlackIcon from '../../Assets/Images/menu-black.png';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { useTheme } from '../../Contexts/ThemeProvider';
import { useIsFocused } from "@react-navigation/native";
import { DrawerActions } from '@react-navigation/native';
import moment from 'moment';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RBSheet from "react-native-raw-bottom-sheet";
import { Image } from 'expo-image';
import Toast from 'react-native-toast-message';
import { RefreshControl } from 'react-native-web-refresh-control';
import Spinner from 'react-native-loading-spinner-overlay';
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';
import HamburgerIcon from '../../Assets/Images/hamburger.svg';
import { BLACK, DARK_GREY, LAVENDER_MIST, LIGHT_GREY, PRIMARY, RED, SOFT_PEACH, WHITE } from '../../Config/colors';

const Orders = (props) => {
    const dispatch = useDispatch()
    const { dark, colors } = useTheme();
    const [selectedTab, setSelectedTab] = useState('PENDING')
    const [selectedSegment, setSelectedSegment] = useState('NFO')
    const [isLoading, setLoading] = useState(false)
    const [allOrders, setAllOrders] = useState([])
    const isFocused = useIsFocused();
    const [modalVisible, setModalVisible] = useState(false);
    const [editData, setEditData] = useState()
    const refRBSheet = useRef();
    const [lot, setLot] = useState()
    const [quantity, setQuantity] = useState()
    const [price, setPrice] = useState('')
    const [isSpinner, setIsSpinner] = useState(false)

    const _onMenuPress = () => {
        props.navigation.dispatch(DrawerActions.openDrawer());
    }

    useEffect(() => {
        checkTab()
    }, [isFocused])

    const checkTab = async () => {
        await AsyncStorage.getItem('tab')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setSelectedSegment(data.currenTab)
                    getData(data.currenTab, 'PENDING')
                } else {
                    getData('NFO', 'PENDING')
                }
            })
            .catch(error => {
                getData('NFO', 'PENDING')
            });
    }

    const getData = (exc, tab) => {
        setLoading(true)
        axios.get(`${BASE_URL}/trade-list?trade_status=${tab}&order_type=${tab == 'PENDING' ? 'LIMIT,STOPLOSS,INTRADAY' : 'LIMIT,STOPLOSS'}&exchange=${exc == 'ALL' ? '' : exc}&day=TODAY`)
            .then((res) => {
                console.log("res", res.data)
                setLoading(false)
                setAllOrders(res.data)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const onChangeTab = async (value) => {
        const data = JSON.stringify({ currenTab: value })
        await AsyncStorage.setItem('tab', data)
        setSelectedSegment(value)
        getData(value, selectedTab)
    }

    const onDeleteOrder = () => {
        setIsSpinner(true)
        axios.delete(`${BASE_URL}/trade/${editData.id}`)
            .then((res) => {
                setIsSpinner(false)
                setModalVisible(false)
                getData(selectedSegment, selectedTab)
                Toast.show({
                    type: 'success',
                    text1: res.data.message,
                    visibilityTime: 1000
                });
            })
            .catch((e) => {
                setIsSpinner(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const onChangingLot = (value) => {
        setLot(value)
        setQuantity((value * Number(editData?.script?.custum_script?.lot_size)).toString())
    }

    const onChangingQuantity = (value) => {
        setQuantity(value.replace(/[^0-9]/g, ''))
        var data = (Number(value) / Number(editData?.script?.custum_script?.lot_size))
        setLot(data.toFixed(2))
    }

    const onEdit = () => {
        setModalVisible(false)
        setIsSpinner(true)
        refRBSheet.current.close()
        var data = {
            ...editData,

        }
        data.lot = lot
        data.quantity = quantity
        data.price = price
        data.created_at = ''
        data.updated_at = ''
        axios.post(`${BASE_URL}/update-trade`, data)
            .then((res) => {
                setIsSpinner(false)
                Toast.show({
                    type: 'success',
                    text1: res.data?.message,
                    visibilityTime: 1000
                });
                getData(selectedSegment, selectedTab)
            })
            .catch((e) => {
                if (e.response?.data?.message) {
                    Toast.show({
                        type: 'error',
                        text1: e.response.data.message,
                        visibilityTime: 1000
                    });
                }
                setIsSpinner(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }


    return (
        <>
            <Spinner
                visible={isSpinner}
                textContent={'Loading...'}
                textStyle={styles.spinnerTextStyle}
            />
            <Modal animationType="slide" transparent={true} visible={modalVisible}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <View >
                            <View style={{ backgroundColor: 'white', borderRadius: 20 }}>
                                <Text style={{ textAlign: 'center', fontSize: 15, color: colors.Secondary, fontWeight: '600', marginTop: 20 }}>{editData?.trading_symbol}</Text>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                    {
                                        editData?.order_type != 'INTRADAY' &&
                                        <Pressable style={styles.cancelButton} onPress={() => {
                                            if (editData?.order_type != 'INTRADAY') {
                                                setPrice(editData?.price.toString())
                                                setLot(editData?.lot.toString())
                                                setQuantity(editData?.quantity.toString())
                                                refRBSheet.current.open()
                                                setModalVisible(false)
                                            }
                                        }}>
                                            <Text style={styles.cancelText}>Edit</Text>
                                        </Pressable>
                                    }

                                    <Pressable style={styles.cancelButton} onPress={() => onDeleteOrder()}>
                                        <Text style={styles.cancelText}>Delete</Text>
                                    </Pressable>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
            <RBSheet
                ref={refRBSheet}
                closeOnDragDown={true}
                closeOnPressMask={true}
                height={205}
                customStyles={{
                    wrapper: {
                        backgroundColor: "transparent",
                    },
                    draggableIcon: {
                        backgroundColor: "#000",
                    },
                    container: {
                        borderTopRightRadius: 20,
                        borderTopLeftRadius: 20,
                        backgroundColor: PRIMARY
                    }
                }}
            >
                <View style={{ marginLeft: 10, flexDirection: 'row', justifyContent: 'space-between', marginRight: 20 }}>
                    <View>
                        <Text style={{ color: WHITE, fontSize: 22, fontWeight: '700' }}>{editData?.trading_symbol}</Text>
                    </View>
                    <View style={{ padding: 5 }}>
                        <Text style={{ color: editData?.order_action == 'SELL' ? '#FF033E' : '#add8e6', fontSize: 15, fontFamily: 'Roboto-Bold' }}>{editData?.order_action}</Text>
                    </View>
                    <View style={{ padding: 5 }}>
                        <Text style={{ color: editData?.order_action == 'SELL' ? '#FF033E' : '#add8e6', fontSize: 15, fontFamily: 'Roboto-Bold' }}>{editData?.order_type}</Text>
                    </View>
                </View>
                <View style={{ backgroundColor: WHITE, height: '80%' }}>
                    <View style={{ flexDirection: 'row', marginLeft: 10, marginRight: 20, marginTop: 10, justifyContent: 'space-evenly' }}>
                        <View style={{ width: '31%' }}>
                            <Text style={{ color: colors.Secondary, marginBottom: 2, fontWeight: '400', textAlign: 'center' }}>Lot</Text>
                            <TextInput style={{ borderWidth: 1, width: '100%', borderRadius: 10, borderColor: 'grey', height: 40, textAlign: 'center', color: colors.Secondary, fontSize: 18, fontWeight: 'bold' }} value={lot} onChangeText={text => onChangingLot(text.replace(/[^0-9]/g, ''))} />
                        </View>
                        <View style={{ width: '31%' }}>
                            <Text style={{ color: colors.Secondary, marginBottom: 2, fontWeight: '400', textAlign: 'center' }}>Quatity</Text>
                            <TextInput style={{ borderWidth: 1, width: '100%', borderRadius: 10, borderColor: 'grey', height: 40, textAlign: 'center', color: colors.Secondary, fontSize: 18, fontWeight: 'bold' }} editable={selectedSegment == 'NFO' ? true : false} value={quantity} onChangeText={text => onChangingQuantity(text)} />
                        </View>
                        <View style={{ width: '31%' }}>
                            <Text style={{ color: colors.Secondary, marginBottom: 2, fontWeight: '400', textAlign: 'center' }}>Price</Text>
                            <TextInput style={{ borderWidth: 1, width: '100%', borderRadius: 10, borderColor: 'grey', height: 40, textAlign: 'center', color: colors.Secondary, fontSize: 18, fontWeight: 'bold' }} value={price} onChangeText={text => setPrice(text)} />
                        </View>

                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                        <Pressable style={styles.cancelButton} onPress={() => {
                            refRBSheet.current.close()
                        }}>
                            <Text style={styles.cancelText}>Cancel</Text>
                        </Pressable>
                        <Pressable style={[styles.cancelButton, { backgroundColor: PRIMARY }]} onPress={() => onEdit()}>
                            <Text style={[styles.cancelText, { color: 'white' }]}>Modify</Text>
                        </Pressable>
                    </View>
                </View>
            </RBSheet>
            <SafeAreaView style={styles.container}>
                <View style={styles.topView}>
                    <View style={styles.nameView}>
                        <Pressable onPress={() => _onMenuPress()} style={styles.profileBackIcon}>
                            <HamburgerIcon style={styles.hamburgerIcon} />
                        </Pressable>
                        <Text style={styles.name}>Hi, {global.name}</Text>
                    </View>

                </View>
                <View style={styles.tagView}>
                    <Pressable style={[styles.tag, {
                        backgroundColor: selectedSegment == "ALL" ? PRIMARY : LAVENDER_MIST,
                        marginLeft: 20
                    }]} onPress={() => onChangeTab('ALL')} hitSlop={styles.hit}>
                        <Text style={[styles.tagText, {
                            color: selectedSegment == "ALL" ? WHITE : PRIMARY,
                        }]}>All</Text>
                    </Pressable>
                    <Pressable style={[styles.tag, {
                        backgroundColor: selectedSegment == "NFO" ? PRIMARY : LAVENDER_MIST,
                        marginLeft: 17
                    }]} onPress={() => onChangeTab('NFO')} hitSlop={styles.hit}>
                        <Text style={[styles.tagText, {
                            color: selectedSegment == "NFO" ? WHITE : PRIMARY,
                        }]}>NSE</Text>
                    </Pressable>
                    <Pressable style={[styles.tag, {
                        backgroundColor: selectedSegment == "MCX" ? PRIMARY : LAVENDER_MIST,
                        marginLeft: 17
                    }]} onPress={() => onChangeTab('MCX')} hitSlop={styles.hit}>
                        <Text style={[styles.tagText, {
                            color: selectedSegment == "MCX" ? WHITE : PRIMARY,
                        }]}>MCX</Text>
                    </Pressable>
                </View>
                <View style={styles.orderTypeView}>
                    <Pressable style={[styles.orderTab, {
                        backgroundColor: selectedTab == 'PENDING' ? PRIMARY : LAVENDER_MIST
                    }]} onPress={() => { setSelectedTab('PENDING'), getData(selectedSegment, 'PENDING') }}>
                        <Text style={[styles.tabText, {
                            color: selectedTab == 'PENDING' ? WHITE : PRIMARY
                        }]}>PENDING</Text>
                    </Pressable>
                    <Pressable style={[styles.orderTab, {
                        backgroundColor: selectedTab == 'EXECUTED' ? PRIMARY : LAVENDER_MIST
                    }]} onPress={() => { setSelectedTab('EXECUTED'), getData(selectedSegment, 'EXECUTED') }}>
                        <Text style={[styles.tabText, {
                            color: selectedTab == 'EXECUTED' ? WHITE : PRIMARY
                        }]}>COMPLETED</Text>
                    </Pressable>
                </View>
                {!isLoading && allOrders.length > 0 &&
                    <FlatList
                        data={allOrders}
                        renderItem={({ item, index }) => <Pressable style={[styles.card, { backgroundColor: item.order_action == 'SELL' ? '#edd3d3' : '#d9f2d9', marginTop: index == 0 ? 20 : 8 }]} onPress={() => { selectedTab == 'PENDING' && setModalVisible(true), setEditData(item) }}>
                            <View style={styles.cardTopView}>
                                <View style={styles.stockNameView}>
                                    <Text style={styles.stockName}>{item.trading_symbol}</Text>
                                </View>
                                <View>
                                    <Text style={styles.date}>{item.updated_at ? moment(item.updated_at).format('DD/MM/YYYY HH:mm:ss') : moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}</Text>
                                </View>
                            </View>
                            <View style={styles.cardBottomView}>
                                <View style={styles.stockNameView}>
                                    <Text style={[styles.type, { color: item.order_action == 'SELL' ? RED : '#1034A6' }]}>{item.order_type != 'INTRADAY' && item.order_action}</Text>
                                    <Text style={[styles.subType, { color: item.order_action == 'SELL' ? RED : '#1034A6' }]}> {item.order_type == 'LIMIT' ? 'Limit' : item.order_type == 'STOPLOSS' ? 'StopLoss' : item.order_type == 'INTRADAY' ? 'Intraday Close Limit' : ' '}</Text>
                                </View>
                                <View>
                                    <Text style={styles.price}>{item.order_type != 'INTRADAY' ? `${item?.price && (Number(item?.price).toFixed(2))}` : ''}</Text>
                                </View>
                                <View>
                                    <Text style={styles.qty}>{item.order_type != 'INTRADAY' ? `Qty: ${item?.quantity} (${item?.lot && Number(item?.lot).toFixed(2)})` : ''}</Text>
                                </View>
                            </View>
                        </Pressable>}
                        keyExtractor={item => item.id}
                        refreshControl={
                            <RefreshControl refreshing={isLoading} onRefresh={() => getData(selectedSegment, selectedTab)} />
                        }
                    />
                }
                {isLoading && allOrders.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color={BLACK} />
                </View>}
                {!isLoading && allOrders.length == 0 && <View style={styles.loaderView}>
                    <Text style={styles.errorMessage}>No Orders Found!</Text>
                </View>}
            </SafeAreaView>
        </>
    )
}

export default Orders;

const styles = StyleSheet.create({
    // container: {
    //     flex: 1
    // },
    // header: {
    //     paddingLeft: 25,
    //     marginTop: 10,
    //     borderBottomWidth: 1,
    //     flexDirection: 'row',
    //     paddingRight: 25,
    //     height: 40,
    //     flexDirection: 'row',
    //     justifyContent: 'space-between'
    // },
    // orderText: {
    //     marginLeft: 20,
    //     fontSize: 18,
    //     fontWeight: '500'
    // },
    // dropdownView: {
    //     marginTop: 20,
    //     marginLeft: 22,
    //     marginRight: 22,
    // },
    // tabView: {
    //     flexDirection: 'row',
    //     // marginLeft: 22,
    //     // marginRight: 22,
    //     // marginTop: 10,
    //     backgroundColor: '#b8e2ff'
    // },
    // tab: {
    //     width: '50%',
    //     alignItems: 'center',
    //     borderBottomWidth: 1,
    //     paddingBottom: 8
    // },
    // segmentTabView: {
    //     flexDirection: 'row',
    //     marginLeft: 22,
    //     marginRight: 22,
    //     marginTop: 10
    // },
    // segementTab: {
    //     width: '50%',
    //     alignItems: 'center',
    //     borderWidth: 1,
    //     borderColor: 'white',
    //     padding: 3,
    //     borderRadius: 5,
    //     marginLeft: 5
    // },
    // item: {
    //     // marginLeft: 22,
    //     // marginRight: 22,
    //     marginTop: 1,
    //     borderRadius: 5,
    //     padding: 10
    // },
    // nameView: {
    //     flexDirection: 'row',
    //     justifyContent: 'space-between'
    // },
    // tradeName: {
    //     fontSize: 16,
    //     fontWeight: '700'
    // },
    // tradeQty: {
    //     color: 'green',
    //     fontSize: 18
    // },
    // priceView: {
    //     flexDirection: 'row',
    //     justifyContent: 'space-between',
    //     marginTop: 5
    // },
    // rate: {
    // },
    // price: {
    //     color: '#5A5A5A',
    // },
    // loaderView: {
    //     flex: 1,
    //     justifyContent: 'center',
    //     alignItems: 'center'
    // },


    container: {
        flex: 1,
        backgroundColor: WHITE
    },
    topView: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    nameView: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    name: {
        color: BLACK,
        fontFamily: 'Roboto-Medium',
        fontSize: 14,
        marginLeft: 17,
        marginTop: 10
    },
    hamburgerIcon: {
        height: 20,
        width: 20
    },
    profileBackIcon: {
        marginLeft: 20,
        backgroundColor: LIGHT_GREY,
        borderRadius: 8,
        padding: 13,
        marginTop: 10
    },
    tagView: {
        flexDirection: 'row'
    },
    tag: {
        borderRadius: 35,
        marginTop: 25
    },
    tagText: {
        fontFamily: 'Roboto-Medium',
        fontSize: 11,
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 16,
        paddingLeft: 16
    },
    hit: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20
    },
    orderTypeView: {
        marginTop: 16,
        flexDirection: 'row'
    },
    orderTab: {
        width: '50%',
        alignItems: 'center'
    },
    tabText: {
        fontSize: 15,
        fontFamily: 'Roboto-Medium',
        marginTop: 13,
        marginBottom: 11
    },
    card: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 8,
        backgroundColor: SOFT_PEACH,
        borderRadius: 12
    },
    cardTopView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 7,
        marginLeft: 12,
        marginRight: 12
    },
    stockName: {
        color: BLACK,
        fontSize: 15,
        fontFamily: 'Roboto-Medium'
    },
    stockNameView: {
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    expiry: {
        color: DARK_GREY,
        fontSize: 12,
        fontFamily: 'Roboto-Bold'
    },
    cardBottomView: {
        marginTop: 8,
        marginBottom: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 12,
        marginRight: 12
    },
    price: {
        color: BLACK,
        fontSize: 16,
        fontFamily: 'Roboto-Bold'
    },
    qty: {
        color: BLACK,
        fontSize: 12,
        fontFamily: 'Roboto-Medium'
    },
    type: {
        color: RED,
        fontSize: 13,
        fontFamily: 'Roboto-Bold'
    },
    subType: {
        color: RED,
        fontSize: 9,
        fontFamily: 'Roboto-Bold'
    },
    loaderView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    errorMessage: {
        color: BLACK,
        fontFamily: 'Roboto-Medium'
    },
    date: {
        color: DARK_GREY,
        fontSize: 12,
        fontFamily: 'Roboto-Medium'
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 10,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    cancelButton: {
        borderRadius: 5,
        marginTop: 15,
        marginLeft: 16,
        marginRight: 16,
        borderWidth: 1
    },
    cancelText: {
        fontSize: 16,
        textAlign: 'center',
        padding: 8,
        backgroundColor: 'transparent',
        marginLeft: 30,
        marginRight: 30
    },
})