import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, View, Image, Pressable, SafeAreaView } from 'react-native';
import { DrawerActions } from '@react-navigation/native';
import { useTheme } from "../../Contexts/ThemeProvider";
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { useIsFocused } from "@react-navigation/native";
import { useDispatch } from 'react-redux';
import { resetAuth } from '../../Actions/AuthActions';
import { Audio } from 'expo-av';
import AsyncStorage from '@react-native-async-storage/async-storage';


import ReportIcon from '../../Assets/Images/report.svg';
import LockIcon from '../../Assets/Images/lock.svg';
import BellIcon from '../../Assets/Images/bell.svg';
import SettingIcon from '../../Assets/Images/setting.svg';
import LogoutIcon from '../../Assets/Images/logout.svg';
import { BLACK, PRIMARY, WHITE } from '../../Config/colors';
import { useFonts } from 'expo-font';

const DrawerContent = (props) => {
    const { dark, colors } = useTheme();
    const userData = useRef()
    const currentPosition = useRef('')
    const isFocused = useIsFocused();
    const dispatch = useDispatch()
    const [checkSoundAlert, setCheckSoundAlert] = useState(false);
    const [fontsLoaded] = useFonts({
        'Roboto-Medium': require('../../Assets/Fonts/Roboto-Medium.ttf'),
        'Roboto-Bold': require('../../Assets/Fonts/Roboto-Bold.ttf'),
        'Roboto-Regular': require('../../Assets/Fonts/Roboto-Regular.ttf'),
    });

    useEffect(() => {
        getUserData()
        checkOtherOptions()
    }, [new Date()])

    useEffect(() => {
        Audio.setAudioModeAsync({
            staysActiveInBackground: true,
            playsInSilentModeIOS: true,
        });
    }, []);

    const onLayoutRootView = useCallback(async () => {
    }, [fontsLoaded]);

    const getUserData = () => {
        axios.get(`${BASE_URL}/me`)
            .then((res) => {
                userData.current = res.data
                getCurrentPosition(res.data.id)
            })
            .catch((e) => {
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const checkOtherOptions = async () => {
        await AsyncStorage.getItem('SOUND')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setCheckSoundAlert(data)
                } else {
                }
            })
            .catch(error => {
            });
    }

    const getCurrentPosition = (id) => {
        axios.get(`${BASE_URL}/get-user-m2m?id=${id}`)
            .then((res) => {
                currentPosition.current = res.data
            })
            .catch((e) => {
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const onLogout = async () => {
        props.navigation.dispatch(DrawerActions.closeDrawer())
        dispatch(resetAuth())
        if (checkSoundAlert) {
            onPlaySound()
        }
    }

    const onPlaySound = async () => {
        const { sound } = await Audio.Sound.createAsync(
            require('../../Assets/sound/login_sound.mp3'),
            undefined,
            null,
            false
        );
        await sound.playAsync();
        setTimeout(() => {
            sound.unloadAsync();
        }, 10000)
    }

    return (
        <>
            <SafeAreaView style={styles.container} onLayout={onLayoutRootView}>
                <View style={styles.topView}>
                    <Text style={styles.userName}>{userData.current?.name}</Text>
                    <Text style={styles.userCode}>{userData.current?.code}</Text>
                    <View style={styles.pointsView}>
                        <View>
                            <Text style={styles.pointsTitle}>Credited Points:</Text>
                            <Text style={styles.pointsTitle}>M2M Points:</Text>
                            <Text style={[styles.pointsTitle, {
                                fontFamily: 'Roboto-Bold'
                            }]}>Net Points:</Text>
                        </View>
                        <View>
                            <Text style={styles.pointsValue}>{userData.current?.ledger_balance && userData.current?.ledger_balance.toFixed(2)}</Text>
                            <Text style={styles.pointsValue}>{currentPosition.current && currentPosition.current.toFixed(2)}</Text>
                            <Text style={[styles.pointsValue, {
                                fontFamily: 'Roboto-Bold'
                            }]}>{(Number(currentPosition.current) + Number(userData.current?.ledger_balance)).toFixed(2)}</Text>
                        </View>
                    </View>
                </View>
                <Pressable style={styles.item} onPress={() => props.navigation.navigate('LedgerReport')}>
                    <View style={styles.iconBackView}>
                        <ReportIcon />
                    </View>
                    <Text style={styles.itemTitle}>Ledger Report</Text>
                </Pressable>
                <Pressable style={styles.item} onPress={() => props.navigation.navigate('RejectionReport')}>
                    <View style={styles.iconBackView}>
                        <ReportIcon />
                    </View>
                    <Text style={styles.itemTitle}>Rejection Report</Text>
                </Pressable>
                <Pressable style={styles.item} onPress={() => props.navigation.navigate('QuantityReport')}>
                    <View style={styles.iconBackView}>
                        <ReportIcon />
                    </View>
                    <Text style={styles.itemTitle}>Quantity Report</Text>
                </Pressable>
                <Pressable style={styles.item} onPress={() => props.navigation.navigate('ChangePassword')}>
                    <View style={styles.iconBackView}>
                        <LockIcon />
                    </View>
                    <Text style={styles.itemTitle}>Change Password</Text>
                </Pressable>
                <Pressable style={styles.item} onPress={() => props.navigation.navigate('Notification')}>
                    <View style={styles.iconBackView}>
                        <BellIcon />
                    </View>
                    <Text style={styles.itemTitle}>Notifications</Text>
                </Pressable>
                <Pressable style={styles.item} onPress={() => props.navigation.navigate('Setting')}>
                    <View style={styles.iconBackView}>
                        <SettingIcon />
                    </View>
                    <Text style={styles.itemTitle}>Settings</Text>
                </Pressable>
                <Pressable style={[styles.item, {
                    bottom: 15,
                    position: 'absolute'
                }]} onPress={() => onLogout()}>
                    <View style={styles.iconBackView}>
                        <LogoutIcon />
                    </View>
                    <Text style={styles.itemTitle}>Log Out</Text>
                </Pressable>
            </SafeAreaView>
        </>
    )
}

export default DrawerContent;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: WHITE
    },
    topView: {
        backgroundColor: PRIMARY,
        borderBottomRightRadius: 11,
    },
    userName: {
        textAlign: 'center',
        color: WHITE,
        fontSize: 13,
        fontFamily: 'Roboto-Medium',
        marginTop: 9
    },
    userCode: {
        textAlign: 'center',
        color: WHITE,
        fontSize: 13,
        fontFamily: 'Roboto-Medium',
        marginTop: 5
    },
    pointsView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
    },
    pointsTitle: {
        color: WHITE,
        fontSize: 13,
        fontFamily: 'Roboto-Regular',
        marginTop: 5
    },
    pointsValue: {
        color: WHITE,
        fontSize: 14,
        fontFamily: 'Roboto-Medium',
        marginTop: 5,
        textAlign: 'right'
    },
    iconBackView: {
        backgroundColor: PRIMARY,
        height: 45,
        width: 45,
        borderRadius: 45 / 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    item: {
        flexDirection: 'row',
        marginTop: 10,
        marginLeft: 10,
        alignItems: 'center'
    },
    itemTitle: {
        color: BLACK,
        fontSize: 14,
        fontFamily: 'Roboto-Medium',
        marginLeft: 15
    }
})