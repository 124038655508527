import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text, Pressable, FlatList, ActivityIndicator, SafeAreaView } from 'react-native';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import moment from 'moment';
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';
import { BLACK, GREY, LAVENDER_MIST, PRIMARY, WHITE } from '../../Config/colors';
import LeftArrow from '../../Assets/Images/arrow-left.svg';

const Notification = (props) => {
    const dispatch = useDispatch()
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        getAllNotifications()
    }, [])

    const getAllNotifications = () => {
        setLoading(true)
        axios.get(`${BASE_URL}/message-list`)
            .then((res) => {
                setLoading(false)
                setNotifications(res.data)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    return (
        <>
            <SafeAreaView style={styles.container}>
                <View style={styles.header}>
                    <Pressable onPress={() => props.navigation.goBack()} hitSlop={styles.hit}>
                        <LeftArrow style={styles.leftIcon} />
                    </Pressable>
                    <Text style={styles.notificationText}>Notification</Text>
                </View>
                {
                    notifications.length > 0 && <FlatList
                        data={notifications}
                        renderItem={({ item, index }) => <View style={[styles.item, { marginTop: index == 0 ? 20 : 8, marginBottom: index == notifications.length - 1 ? 30 : 0, backgroundColor: item.is_read == 0 ? LAVENDER_MIST : WHITE }]}>
                            <Text style={styles.date}>{item.created_at && moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}</Text>
                            <Text style={styles.message}>{item.description}</Text>
                        </View>}
                        keyExtractor={item => item.id}
                    />
                }
                {isLoading && notifications.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color={BLACK} />
                </View>}
                {
                    !isLoading && notifications.length == 0 &&
                    <View style={styles.loaderView}>
                        <Text style={styles.errorMessage}>No Notifications Found!</Text>
                    </View>
                }
            </SafeAreaView>
        </>
    )
}

export default Notification;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: WHITE
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    leftIcon: {
        marginLeft: 28,
        marginTop: 20
    },
    notificationText: {
        fontSize: 20,
        marginTop: 20,
        marginLeft: 15,
        color: PRIMARY,
        fontFamily: 'Roboto-Medium'
    },
    item: {
        backgroundColor: LAVENDER_MIST,
        marginLeft: 25,
        marginRight: 25,
        marginTop: 30,
        borderRadius: 16,
        borderWidth: 1,
        borderColor: GREY
    },
    date: {
        marginTop: 7,
        marginLeft: 14,
        color: PRIMARY,
        fontSize: 11,
        fontFamily: 'Roboto-Medium'
    },
    message: {
        color: BLACK,
        marginLeft: 14,
        fontFamily: 'Roboto-Medium',
        fontSize: 14,
        marginBottom: 7,
        marginTop: 3
    },
    hit: {
        top: 20,
        bottom: 20,
        left: 50,
        right: 50
    },
    loaderView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    errorMessage: {
        color: BLACK,
        fontFamily: 'Roboto-Medium'
    }
})