import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Text, Image, Pressable, TextInput, FlatList, Modal, ActivityIndicator, SafeAreaView } from 'react-native';
import { useTheme } from '../../Contexts/ThemeProvider';
import backIcon from '../../Assets/Images/backArrow.png';
import backBlackIcon from '../../Assets/Images/back-arrow-black.png';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import moment from 'moment';
import filterBlack from '../../Assets/Images/filterBlack.png';
import filterWhite from '../../Assets/Images/filterWhite.png';
import { LinearGradient } from 'expo-linear-gradient';
import { Width, color1 } from '../../Utility/Utility';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';
import { BLACK, DARK_GREY, GREY, LAVENDER_MIST, PRIMARY, WHITE, ZAMBEZI } from '../../Config/colors';
import LeftArrow from '../../Assets/Images/arrow-left.svg';
import FilterIcon from '../../Assets/Images/filter.svg';

const RejectionReport = (props) => {
    const dispatch = useDispatch()
    const { dark, colors } = useTheme();
    const _onBack = () => props.navigation.goBack()
    const userId = useRef('')
    const [reportData, setReportData] = useState([])
    const [modalVisible, setModalVisible] = useState(false);
    const [date, setDate] = useState(new Date())
    const [enddate, setEndDate] = useState(new Date())
    const [open, setOpen] = useState(false)
    const [dateType, setDateType] = useState('')
    const [selectedSegment, setSelectedSegment] = useState('NSE')
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        getData()
        getReport()
    }, [])

    const getData = async () => {
        try {
            const jsonValue = await AsyncStorage.getItem('user_detail')
            const data = jsonValue != null ? JSON.parse(jsonValue) : null;
            userId.current = data.id
        } catch (e) {
            // error reading value
        }
    }

    const getReport = (fromDate, toDate) => {
        setLoading(true)
        var fDate = fromDate ? moment(fromDate).format('YYYY-MM-DD') : ''
        var tDate = toDate ? moment(toDate).format('YYYY-MM-DD') : ''
        axios.get(`${BASE_URL}/trade-list?trade_status=REJECTED&from_date=${fDate}&to_date=${tDate}`)
            .then((res) => {
                setLoading(false)
                setReportData(res.data)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button style={{ color: BLACK, borderRadius: 12, height: 45, width: Width * 0.9, backgroundColor: WHITE, borderColor: PRIMARY }} onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    return (
        <>
            <SafeAreaView style={styles.container}>
                <View style={styles.header}>
                    <View style={styles.nameView}>
                        <Pressable onPress={() => props.navigation.goBack()} hitSlop={styles.hit}>
                            <LeftArrow style={styles.leftIcon} />
                        </Pressable>
                        <Text style={styles.rejectionReportText}>Rejection Report</Text>
                    </View>
                    <Pressable onPress={() => setModalVisible(true)}>
                        <FilterIcon style={styles.filterIcon} />
                    </Pressable>
                </View>
                <View style={styles.tableView}>
                    <View style={styles.tableHeader}>
                        <Text style={styles.tableHeaderTitle}>Time</Text>
                        <Text style={styles.tableHeaderTitle}>Script</Text>
                        <Text style={styles.tableHeaderTitle}>Type</Text>
                        <Text style={[styles.tableHeaderTitle]}>Lot</Text>
                        <Text style={styles.tableHeaderTitle}>Qty</Text>
                        <Text style={styles.tableHeaderTitle}>Rate</Text>
                        <Text style={styles.tableHeaderTitle}>Reason</Text>
                    </View>
                    {
                        reportData.length > 0 && <FlatList
                            data={reportData}
                            renderItem={({ item, index }) => <View style={[styles.tableContent, { borderBottomWidth: index == reportData.length - 1 ? 0 : 1 }]} key={new Date()}>
                                <Text style={[styles.tableItem, { borderLeftWidth: 1 }]}>{moment(item.created_at).format('DD/MM/YYYY hh:mm A')}</Text>
                                <Text style={styles.tableItem}>{item?.script?.trading_symbol}</Text>
                                <Text style={styles.tableItem}>{item.order_action}</Text>
                                <Text style={[styles.tableItem]}>{item.lot}</Text>
                                <Text style={styles.tableItem}>{item.quantity}</Text>
                                <Text style={styles.tableItem}>{item.price}</Text>
                                <Text style={styles.tableItem}>{item.reject_reason}</Text>
                            </View>}
                            keyExtractor={item => item.id}
                        />
                    }
                    {
                        !isLoading && reportData.length == 0 &&
                        <View style={styles.errorView}>
                            <Text style={styles.errorMessage}>No Rejection Found!</Text>
                        </View>
                    }
                    {isLoading && reportData.length == 0 && <View style={styles.errorView}>
                        <ActivityIndicator size="small" color={BLACK} />
                    </View>}
                </View>
            </SafeAreaView>
            <Modal animationType="slide" transparent={true} visible={modalVisible}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        {/* <View >
                            <View style={{}}>
                                <Text style={{ color: colors.Secondary, fontSize: 18, fontWeight: 'bold', marginTop: 10 }}>Filter</Text>
                                <View style={styles.segmentTabView}>
                                    <Pressable style={[styles.segementTab, { backgroundColor: selectedSegment == 'NSE' ? colors.Secondary : colors.Primary, borderColor: colors.Secondary }]} onPress={() => { setSelectedSegment('NSE') }}>
                                        <Text style={[styles.tabText, { color: selectedSegment == 'NSE' ? colors.Primary : colors.Secondary }]}>NSE</Text>
                                    </Pressable>
                                    <Pressable style={[styles.segementTab, { backgroundColor: selectedSegment == 'MCX' ? colors.Secondary : colors.Primary, borderColor: colors.Secondary }]} onPress={() => { setSelectedSegment('MCX') }}>
                                        <Text style={[styles.tabText, { color: selectedSegment == 'MCX' ? colors.Primary : colors.Secondary }]}>MCX</Text>
                                    </Pressable>
                                </View>
                                <Text style={styles.label}>From Date</Text>
                                <DatePicker selected={date} onChange={(date) => setDate(date)} customInput={<ExampleCustomInput />} />
                                <Text style={styles.label}>To Date</Text>
                                <DatePicker selected={enddate} onChange={(date) => setEndDate(date)} customInput={<ExampleCustomInput />} />
                                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                    <Pressable style={styles.cancelButton} onPress={() => {
                                        setDate(new Date())
                                        setEndDate(new Date())
                                        setModalVisible(false)
                                        setSelectedSegment('')
                                        getReport()
                                    }}>
                                        <Text style={styles.cancelText}>Reset</Text>
                                    </Pressable>
                                    <Pressable style={styles.cancelButton} onPress={() => setModalVisible(!modalVisible)}>
                                        <Text style={styles.cancelText}>Cancel</Text>
                                    </Pressable>
                                    <Pressable onPress={() => { setModalVisible(false), getReport(date, enddate) }}>
                                        <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={['#26D0CE', '#1A2980']} style={styles.continueButton}>
                                            <Text style={styles.continueText}>Continue</Text>
                                        </LinearGradient>
                                    </Pressable>
                                </View>
                            </View>
                        </View> */}
                        <Text style={styles.filterTitle}>Filter</Text>
                        <View style={styles.tagView}>
                            <Pressable style={[styles.tag, {
                                backgroundColor: selectedSegment == "NSE" ? PRIMARY : LAVENDER_MIST,
                                marginLeft: 20
                            }]} onPress={() => setSelectedSegment('NSE')} hitSlop={styles.hit}>
                                <Text style={[styles.tagText, {
                                    color: selectedSegment == "NSE" ? WHITE : PRIMARY,
                                }]}>NSE</Text>
                            </Pressable>
                            <Pressable style={[styles.tag, {
                                backgroundColor: selectedSegment == "MCX" ? PRIMARY : LAVENDER_MIST,
                                marginLeft: 17
                            }]} onPress={() => setSelectedSegment('MCX')} hitSlop={styles.hit}>
                                <Text style={[styles.tagText, {
                                    color: selectedSegment == "MCX" ? WHITE : PRIMARY,
                                }]}>MCX</Text>
                            </Pressable>
                        </View>
                        <Text style={styles.label}>From Date</Text>
                        <DatePicker selected={date} onChange={(date) => setDate(date)} customInput={<ExampleCustomInput />} />
                        <Text style={styles.label}>To Date</Text>
                        <DatePicker selected={enddate} onChange={(date) => setEndDate(date)} customInput={<ExampleCustomInput />} />
                        <View style={styles.buttonView}>
                            <Pressable style={[styles.saveButton, { backgroundColor: WHITE, borderColor: PRIMARY, borderWidth: 1, marginRight: 20 }]} onPress={() => {
                                setDate(new Date())
                                setEndDate(new Date())
                                setModalVisible(false)
                                setSelectedSegment('NSE')
                                getReport()
                            }}>
                                <Text style={[styles.saveButtonText, { color: PRIMARY }]}>Reset</Text>
                            </Pressable>
                            <Pressable style={[styles.saveButton, { backgroundColor: WHITE, borderColor: PRIMARY, borderWidth: 1, marginRight: 20 }]} onPress={() => setModalVisible(!modalVisible)}>
                                <Text style={[styles.saveButtonText, { color: PRIMARY }]}>Cancel</Text>
                            </Pressable>
                            <Pressable style={styles.saveButton} onPress={() => { setModalVisible(false), getReport(date, enddate) }}>
                                <Text style={styles.saveButtonText}>Apply</Text>
                            </Pressable>
                        </View>
                    </View>
                </View>
            </Modal>
        </>
    )
}

export default RejectionReport;

const styles = StyleSheet.create({
    // container: {
    //     flex: 1
    // },
    // header: {
    //     paddingLeft: 25,
    //     marginTop: 10,
    //     borderBottomWidth: 1,
    //     flexDirection: 'row',
    //     paddingRight: 25,
    //     height: 40
    // },
    // titleView: {
    //     alignItems: 'center',
    //     width: '90%'
    // },
    // headerTitle: {
    //     fontSize: 16,
    // },
    // filterIcon: {
    //     height: 25,
    //     width: 25,
    //     marginTop: 3,
    //     marginRight: 10
    // },
    // headerIcon: {
    //     flexDirection: 'row'
    // },
    // dateInput: {
    //     borderRadius: 12,
    //     width: Width * 0.9,
    //     height: 45,
    //     paddingLeft: 15,
    //     bottom: 6,
    //     marginTop: 10,
    //     justifyContent: 'center'
    // },
    // label: {
    //     color: color1,
    //     fontSize: 15,
    //     fontFamily: 'Roboto-Regular',
    //     fontWeight: '600',
    //     marginTop: 10
    // },
    // cancelButton: {
    //     borderRadius: 5,
    //     marginTop: 18,
    //     marginLeft: 10,
    //     borderWidth: 1
    // },
    // cancelText: {
    //     fontSize: 16,
    //     textAlign: 'center',
    //     padding: 10,
    //     backgroundColor: 'transparent',
    //     marginLeft: 15,
    //     marginRight: 15
    // },
    // continueButton: {
    //     borderRadius: 5,
    //     marginTop: 18,
    //     marginLeft: 10,
    // },
    // continueText: {
    //     fontSize: 16,
    //     textAlign: 'center',
    //     color: 'white',
    //     padding: 10,
    //     backgroundColor: 'transparent',
    //     marginLeft: 15,
    //     marginRight: 15
    // },
    // segmentTabView: {
    //     flexDirection: 'row',
    //     marginLeft: 22,
    //     marginRight: 22,
    //     marginTop: 10
    // },
    // segementTab: {
    //     width: '50%',
    //     alignItems: 'center',
    //     borderWidth: 1,
    //     padding: 3,
    //     borderRadius: 5,
    //     marginLeft: 5
    // },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 10,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    // loaderView: {
    //     flex: 1,
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     marginBottom: 50
    // },
    container: {
        flex: 1,
        backgroundColor: WHITE
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: 20
    },
    leftIcon: {
        marginLeft: 28,
        marginTop: 20
    },
    rejectionReportText: {
        fontSize: 20,
        marginTop: 20,
        marginLeft: 15,
        color: PRIMARY,
        fontFamily: 'Roboto-Medium'
    },
    hit: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20
    },
    tableView: {
        marginTop: 30,
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        padding: 8,
        borderRadius: 10,
        borderColor: DARK_GREY,

    },
    tableHeader: {
        width: '100%',
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: GREY,
        backgroundColor: GREY,
        // borderRadius: 50
    },
    tableHeaderTitle: {
        fontSize: 9,
        fontFamily: 'Roboto-Bold',
        width: '15%',
        textAlign: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        color: BLACK,
    },
    tableContent: {
        flexDirection: 'row',
        width: '100%',
        borderBottomWidth: 1,
        borderColor: GREY
    },
    tableItem: {
        fontSize: 12,
        fontFamily: 'Roboto-Medium',
        textAlign: 'center',
        width: '15%',
        paddingTop: 5,
        paddingBottom: 5,
        color: BLACK,
        borderRightWidth: 1,
        borderColor: GREY
    },
    errorView: {
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        flex: 1
    },
    errorMessage: {
        color: BLACK,
        fontFamily: 'Roboto-Medium',
        marginTop: 30,
        marginBottom: 30
    },
    nameView: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    filterIcon: {
        marginTop: 20
    },
    filterTitle: {
        fontSize: 15,
        color: BLACK,
        fontFamily: 'Roboto-Medium',
        marginLeft: 5,
        marginTop: 10
    },
    tagView: {
        flexDirection: 'row'
    },
    tag: {
        borderRadius: 35,
        marginTop: 10,
    },
    tagText: {
        fontFamily: 'Roboto-Medium',
        fontSize: 14,
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 16,
        paddingLeft: 16
    },
    label: {
        color: ZAMBEZI,
        marginBottom: 5,
        marginTop: 10,
        fontFamily: 'Roboto-Medium',
        fontSize: 13
    },
    saveButton: {
        backgroundColor: PRIMARY,
        alignSelf: 'center',
        marginTop: 20,
        borderRadius: 16
    },
    saveButtonText: {
        color: WHITE,
        fontSize: 20,
        fontFamily: 'Roboto-Bold',
        marginTop: 10,
        marginBottom: 9,
        marginLeft: 20,
        marginRight: 20
    },
    buttonView: {
        flexDirection: 'row'
    }
})