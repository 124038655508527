import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    View,
    StyleSheet,
    StatusBar,
    Text,
    TextInput,
    Pressable,
    ActivityIndicator,
    ImageBackground,
    SafeAreaView,
    ScrollView,
} from 'react-native';
import { Image } from 'expo-image';
import { color1, color2, color3, Primary, Secondary, Width } from "../../Utility/Utility";
import { LinearGradient } from 'expo-linear-gradient';
import axios from "axios";
import { BASE_URL } from "../../Config";
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_ERROR, AUTH_SERVICE_RUNNING, SET_USER, SET_LOGIN_SUCCESS } from "../../Actions/Types";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useTheme } from "../../Contexts/ThemeProvider";
import loginImage from '../../Assets/Images/loginImage.jpg';
import { resetAuth } from "../../Actions/AuthActions";
import passwordShow from "../../Assets/Images/eye.png";
import passwordHide from "../../Assets/Images/hide.png";
import { Audio } from 'expo-av';
import Toast from 'react-native-toast-message';
import { BLACK, PRIMARY, WHITE, ZAMBEZI } from "../../Config/colors";
import { moderateScale, scale, verticalScale } from "react-native-size-matters";
import { useFonts } from "expo-font";
import EyeOpenIcon from '../../Assets/Images/eyeOpen.svg';
import EyeCloseIcon from '../../Assets/Images/eyeClose.svg'

const Login = ({ navigation, route, props }) => {
    const { dark, colors } = useTheme();
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [serverCode, setServerCode] = useState('')
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const { data, isSelect } = route.params
    const [isShow, setIsShow] = useState(true)
    const checkSoundAlert = useRef()
    const [fontsLoaded] = useFonts({
        'Roboto-Medium': require('../../Assets/Fonts/Roboto-Medium.ttf'),
        'Roboto-Bold': require('../../Assets/Fonts/Roboto-Bold.ttf'),
        'Roboto-Regular': require('../../Assets/Fonts/Roboto-Regular.ttf'),
    });
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        if (data) {
            setUsername(data.code)
            setPassword(data.password)
            setServerCode(data.server_code.toString())

            setTimeout(() => {
                onLogin(data.code, data.password, data.server_code)
            }, 1000);
        }
        checkOtherOptions()
    }, [])

    useEffect(() => {
        Audio.setAudioModeAsync({
            staysActiveInBackground: true,
            playsInSilentModeIOS: true,
        });
    }, []);

    const onLayoutRootView = useCallback(async () => {
    }, [fontsLoaded]);

    const checkOtherOptions = async () => {
        await AsyncStorage.getItem('SOUND')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    checkSoundAlert.current = data
                } else {
                }
            })
            .catch(error => {
            });
    }

    const onPlaySound = async () => {
        const { sound } = await Audio.Sound.createAsync(
            require('../../Assets/sound/login_sound.mp3'),
            undefined,
            null,
            false
        );
        await sound.playAsync();
        setTimeout(() => {
            sound.unloadAsync();
        }, 10000)
    }


    const onLogin = (user, pass, code) => {
        setLoading(true)
        axios.post(`${BASE_URL}/ouath-token`, {
            code: user ? user : username,
            password: pass ? pass : password,
            server_code: code ? code : serverCode,
            token: '',
            type: 'ANDROID'
        })
            .then((res) => {
                dispatch({ type: AUTH_SERVICE_RUNNING })
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${res.data.access_token}`
                }
                axios.get(`${BASE_URL}/me`, {
                    headers: headers
                })
                    .then(async (val) => {
                        if (val.data.role == 'USER') {
                            if (window.ReactNativeWebView) {
                                window.ReactNativeWebView.postMessage(
                                    val.data.id
                                );
                            }
                            const jsonValue = JSON.stringify(val.data)
                            await AsyncStorage.setItem('user_detail', jsonValue)
                            await AsyncStorage.getItem('login_users')
                                .then(req => JSON.parse(req))
                                .then(async data => {

                                    if (data) {
                                        var array = data;

                                        if (isSelect == false) {
                                            array.push({ code: val.data.code, password: password, server_code: val.data.server_code, name: val.data.name })
                                            const logData = JSON.stringify(array)
                                            await AsyncStorage.setItem('login_users', logData)
                                                .then(json => console.log('success!'))
                                                .catch(error => console.log('error!'));
                                        }
                                        else {
                                            for (var i in data) {
                                                if (data[i].code == val.data.code) {
                                                    data[i].code = val.data.code;
                                                    data[i].server_code = val.data.server_code;
                                                    data[i].name = val.data.name;
                                                }
                                            }
                                            var newData = JSON.stringify(data)
                                            await AsyncStorage.setItem('login_users', newData)
                                                .then(json => console.log('success!'))
                                                .catch(error => console.log('error!'));

                                        }
                                    } else {
                                        const logData = JSON.stringify([{ code: val.data.code, password: password, server_code: val.data.server_code, name: val.data.name }])
                                        await AsyncStorage.setItem('login_users', logData)
                                            .then(json => console.log('success!'))
                                            .catch(error => console.log('error!'));
                                    }
                                    if (checkSoundAlert.current) {
                                        onPlaySound()
                                    }
                                })
                                .catch((error) =>
                                    console.log('error!')
                                );
                            if (res.data.is_first_login == 1) {
                                navigation.navigate('FirstLoginPassword', {
                                    token: res.data.access_token,
                                    id: res.data.id,
                                })
                            } else {
                                dispatch({ type: SET_USER, payload: { access_token: res.data.access_token } })
                                dispatch({ type: SET_LOGIN_SUCCESS })
                            }
                            setLoading(false)
                        } else {
                            Toast.show({
                                type: 'error',
                                text1: "Invalid Credentials",
                                visibilityTime: 1000
                            });
                            dispatch({ type: AUTH_ERROR, payload: { error: 'error' } })
                            dispatch(resetAuth())
                            setLoading(false)
                        }
                    })
                    .catch((e) => {
                        dispatch({ type: AUTH_ERROR, payload: { error: 'error' } })
                        dispatch(resetAuth())
                        setLoading(false)
                    })
            })
            .catch((e) => {
                console.log("e", e)
                Toast.show({
                    type: 'error',
                    text1: e.response.data.message,
                    visibilityTime: 1000
                });
                dispatch({ type: AUTH_ERROR, payload: { error: 'error' } })
                dispatch(resetAuth())
                setLoading(false)
            })
    }

    return (
        <>
            <StatusBar
                animated={true}
                backgroundColor={Primary}
            />
            <SafeAreaView style={styles.container} onLayout={onLayoutRootView}>
                <Text style={styles.welcomeText}>Hi, Welcome Back! 👋</Text>
                <View style={styles.mainView}>
                    <ScrollView>
                        <Text style={styles.enterDetailText}>Enter your details to login</Text>
                        <Text style={styles.label}>Account Code</Text>
                        <TextInput
                            style={styles.inputStyle}
                            onChangeText={text => setUsername(text)} value={username}
                        />
                        <Text style={[styles.label, { marginTop: verticalScale(10) }]}>Password</Text>
                        {/* <TextInput
                            style={styles.inputStyle}
                            onChangeText={text => setPassword(text)} value={password}
                        /> */}
                        <View style={styles.passwordField}>
                            <TextInput
                                style={styles.passwordInputStyle}
                                onChangeText={text => setPassword(text)} value={password}
                                secureTextEntry={isShow}
                            />
                            {isShow && <Pressable onPress={() => setIsShow(false)}>
                                <EyeOpenIcon fill={PRIMARY} style={styles.eyeIcon} />
                            </Pressable>
                            }
                            {!isShow && <Pressable onPress={() => setIsShow(true)}>
                                <EyeCloseIcon fill={PRIMARY} style={styles.eyeIcon} />
                            </Pressable>
                            }
                        </View>
                        <Text style={[styles.label, { marginTop: verticalScale(10) }]}>Server Code</Text>
                        <TextInput
                            style={styles.inputStyle}
                            onChangeText={text => setServerCode(text)} value={serverCode}
                        />
                        <Text style={styles.noRealMoneyText}>No real money involved. This is a virtual Trading Application for exchanging views on markets. This is for training purpose only
                        </Text>
                        <Pressable style={styles.termsAndCondition} onPress={() => navigation.navigate('TermsAndCondition')}>
                            <Text style={styles.termsText}> Terms & Conditions*</Text>
                        </Pressable>
                        <Pressable style={styles.loginButton} hitSlop={styles.hitSlop} onPress={() => onLogin()} disabled={isLoading}>
                            {isLoading && <ActivityIndicator size="small" color="white" style={{ marginRight: 20 }} />}
                            <Text style={styles.loginButtonText}>Login</Text>
                        </Pressable>
                    </ScrollView>
                </View>
            </SafeAreaView>
        </>
    )
}

export default Login;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: PRIMARY
    },
    mainView: {
        height: '70%',
        backgroundColor: WHITE,
        bottom: 0,
        position: 'absolute',
        width: '100%',
        borderTopLeftRadius: 150
    },
    enterDetailText: {
        color: BLACK,
        alignSelf: 'center',
        marginTop: 40,
        fontFamily: 'Roboto-Medium',
        fontSize: scale(14)
    },
    welcomeText: {
        color: WHITE,
        fontSize: 28,
        fontFamily: 'Roboto-Regular',
        alignSelf: 'center',
        marginTop: 80
    },
    inputStyle: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: '#000000',
        marginLeft: 40,
        marginRight: 40,
        color: BLACK,
        height: 48,
        paddingLeft: 15
    },
    passwordField: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: '#000000',
        marginLeft: 40,
        marginRight: 40,
        color: BLACK,
        height: 48,
        paddingLeft: 15,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    passwordInputStyle: {
        borderWidth: 0,
        borderColor: '#000000',
        width: '85%'
    },
    label: {
        color: ZAMBEZI,
        marginLeft: 40,
        marginBottom: 5,
        marginTop: 27,
        fontFamily: 'Roboto-Medium',
        fontSize: 13
    },
    loginButton: {
        backgroundColor: PRIMARY,
        marginLeft: 40,
        marginRight: 40,
        marginTop: 40,
        borderRadius: 8,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    loginButtonText: {
        color: WHITE,
        paddingTop: 11,
        paddingBottom: 10,
        textAlign: 'center',
        fontFamily: 'Roboto-Bold',
        fontSize: 20
    },
    hitSlop: {
        top: 20,
        bottom: 20,
        left: 50,
        right: 50
    },
    eyeIcon: {
        marginTop: 10,
        marginRight: 10
    },
    noRealMoneyText: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 10,
        fontSize: 12
    },
    termsAndCondition: {
        alignSelf: 'center',
    },
    termsText: {
        color: 'blue'
    }
})