import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { setAuthToken } from "../../Utility/SetHeader";
import { Pressable, SafeAreaView, StyleSheet, Text, View, ScrollView, FlatList } from "react-native";
import { BLACK, DARK_GREY, GREEN, GREY, LIGHT_GREY, PRIMARY, RED, WHITE } from "../../Config/colors";
import HamburgerIcon from '../../Assets/Images/hamburger.svg';
import UpIcon from '../../Assets/Images/upLine.svg';
import DownIcon from '../../Assets/Images/downLine.svg';
import { useFonts } from 'expo-font';
import { DrawerActions, useNavigation } from "@react-navigation/native";
import axios from "axios";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL, SOCKET_URL } from "../../Config";
import { Height, formatter } from "../../Utility/Utility";
import { RefreshControl } from 'react-native-web-refresh-control'
import webstomp from "webstomp-client";
import moment from "moment";
import BriefcaseIcon from '../../Assets/Images/briefcase.svg';

const Home = (props) => {
    const auth = useSelector(state => state.auth)
    const [isFocused, setIsFocused] = useState(false);
    const [marginData, setMarginData] = useState();
    const navigation = useNavigation();
    const [totalMarginAvailable, setTotalMarginAvailable] = useState(0)
    const [isLoading, setLoading] = useState(false);
    const client = useRef();
    const live = useRef([]);
    const niftyPosition = useRef();
    const niftyPoints = useRef();
    const sensexPosition = useRef();
    const sensexPoints = useRef();
    const currentPosition = useRef('')
    const [liveData, setLiveData] = useState([])
    const [fontsLoaded] = useFonts({
        'Roboto-Medium': require('../../Assets/Fonts/Roboto-Medium.ttf'),
        'Roboto-Bold': require('../../Assets/Fonts/Roboto-Bold.ttf')
    });

    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            if (auth.access_token) {
                setAuthToken(auth.access_token)
            }
        }
        return () => {
            isCancelled = true
        }
    }, [isFocused])

    useEffect(() => {
        const unsubscribeFocus = navigation.addListener('focus', () => {
            setIsFocused(true);
        });
        const unsubscribeBlur = navigation.addListener('blur', () => {
            setIsFocused(false);
        });

        return () => {
            unsubscribeFocus();
            unsubscribeBlur();
        };
    }, []);

    useEffect(() => {
        checkTab()
        getUserDetail()
        connect();
    }, [isFocused])

    const onLayoutRootView = useCallback(async () => {
    }, [fontsLoaded]);

    const _onMenuPress = () => {
        props.navigation.dispatch(DrawerActions.openDrawer());
    }

    const getUserDetail = async () => {
        try {
            const jsonValue = await AsyncStorage.getItem('user_detail')
            const data = jsonValue != null ? JSON.parse(jsonValue) : null;
            global.name = data.name
            getMargin(data.id)
            getCurrentPosition(data.id)
        } catch (e) {
            // error reading value
        }
    }

    const getCurrentPosition = (id) => {
        axios.get(`${BASE_URL}/get-user-m2m?id=${id}`)
            .then((res) => {
                currentPosition.current = res.data
            })
            .catch((e) => {
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const checkTab = async () => {
        await AsyncStorage.getItem('tab')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    getData(data.currenTab)
                    getUserDetail()
                } else {
                    getData('NFO')
                    getUserDetail()
                }
            })
            .catch(error => {
                getData('NFO')
                getUserDetail()
            });
    }

    const getData = (value, search) => {
        setLoading(true)
        axios.get(`${BASE_URL}/position-report-list?exchange=${''}`)
            .then((res) => {
                var array = [];
                for (var i in res.data) {
                    res.data[i].isChecked = false;
                    if (i <= 2) {
                        array.push(res.data[i])
                    }
                }
                live.current = array
                setLiveData(array)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const connect = () => {
        var url = SOCKET_URL;
        var login = "market";
        var password = "EasyTrade";
        if (!client.current || !client.current.connected) {
            client.current = webstomp.client(url, { debug: false });
            client.current.connect(login, password, () => {
                client.current.subscribe("/exchange/market-data", (message) => {
                    onMessage(JSON.parse(message.body))
                });
            });
        }
    }
    var isRunning = false;
    const onMessage = (data) => {
        if (!isRunning) {
            if (live.current.length > 0 && data.length > 0) {
                for (var i in data) {
                    if (data[i].it == 265) {
                        sensexPosition.current = data[i].ltp;
                        sensexPoints.current = data[i].n;
                    }
                    if (data[i].it == 256265) {
                        niftyPosition.current = data[i].ltp;
                        niftyPoints.current = data[i].n;
                    }
                    for (var j in live.current) {
                        if (live.current[j].instrument_token == data[i].it) {
                            live.current[j].a = data[i].a.toFixed(2);
                            live.current[j].b = data[i].b.toFixed(2);
                            live.current[j].ac = data[i].ac;
                            live.current[j].bc = data[i].bc;
                            live.current[j].ltpc = data[i].ltpc;
                            live.current[j].c = data[i].c;
                            live.current[j].ltp = data[i].ltp.toFixed(2);
                            live.current[j].it = data[i].it;
                            live.current[j].ch = data[i].ch;
                            live.current[j].h = data[i].h.toFixed(2);
                            live.current[j].l = data[i].l.toFixed(2);
                            live.current[j].o = data[i].o;
                            live.current[j].n = data[i].n;
                            live.current[j].ul = data[i].ul;
                            live.current[j].ll = data[i].ll;
                            live.current[j].ba = data[i].ba;
                        }
                    }
                }

                setLiveData([...live.current])
            }

            isRunning = false;
        }

    }

    const getMargin = (id) => {
        axios.get(`${BASE_URL}/client-summary-report/${id}`)
            .then((res) => {
                setTotalMarginAvailable(Number(res.data.mcx_bal) + Number(res.data.nse_bal))
                setMarginData(res.data)
            })
            .catch((e) => {
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    return (
        <>
            <SafeAreaView style={styles.container} onLayout={onLayoutRootView}>
                <View style={styles.topView}>
                    <View style={styles.nameView}>
                        <Pressable onPress={() => _onMenuPress()} style={styles.profileBackIcon}>
                            <HamburgerIcon style={styles.hamburgerIcon} />
                        </Pressable>
                        <Text style={styles.name}>Hi, {global.name}</Text>
                    </View>

                </View>
                <ScrollView style={{ marginBottom: 30 }}>
                    <View style={styles.marginView}>
                        <View style={styles.margin}>
                            <View>
                                <Text style={styles.totalMarginText}>Total Profit </Text>
                                <Text style={styles.marginAmount}>{currentPosition.current && currentPosition.current.toFixed(2)}</Text>
                            </View>
                            <View>
                                {/* <RightArrowIcon /> */}
                            </View>
                        </View>
                        <Text style={styles.marginText}>Margin Available</Text>
                        <View style={[styles.marginViewNSE, { marginTop: 5, marginBottom: 8 }]}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={styles.marginTitle}>NSE</Text>
                                <Text style={styles.marginValue}>{marginData?.nse_margin - marginData?.total_nse_used_margin}</Text>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={styles.marginTitle}>MCX</Text>
                                <Text style={styles.marginValue}>{marginData?.mcx_margin - marginData?.total_mcx_used_margin}</Text>
                            </View>
                        </View>
                        {/* <View style={styles.profitView}>
                            <Text style={styles.totalProfitText}>Total Margin Available</Text>
                            <Text style={styles.profitAmount}>{totalMarginAvailable && formatter.format(totalMarginAvailable)}</Text>
                        </View> */}
                    </View>
                    <Text style={styles.indexText}>Index</Text>
                    <View style={styles.indexs}>
                        <View style={styles.indexView}>
                            <Text style={styles.indexTitle}>NIFTY 50</Text>
                            <Text style={styles.indexValue}>{niftyPosition.current}</Text>
                            <View style={styles.indexPercentage}>
                                <Text style={[styles.indexPercentageText, { color: niftyPoints.current > 0 ? GREEN : RED }]}>{niftyPoints.current && niftyPoints.current.toFixed(2)}</Text>
                                {/* <Text style={styles.indexPercentageText}>1.29%</Text> */}
                            </View>
                        </View>
                        <View style={styles.indexView}>
                            <Text style={styles.indexTitle}>SENSEX</Text>
                            <Text style={styles.indexValue}>{sensexPosition.current}</Text>
                            <View style={styles.indexPercentage}>
                                <Text style={[styles.indexPercentageText, { color: sensexPoints.current > 0 ? GREEN : RED }]}>{sensexPoints.current && sensexPoints.current.toFixed(2)}</Text>
                                {/* <Text style={styles.indexPercentageText}>1.29%</Text> */}
                            </View>
                        </View>
                    </View>
                    <Text style={styles.indexText}>Watchlist</Text>
                    {!isLoading && liveData.length > 0 &&
                        <View style={{ height: Height * 0.7 }}>
                            <FlatList
                                data={liveData}
                                initialNumToRender={5}
                                renderItem={({ item, index }) =>
                                    <View style={styles.item}>
                                        {
                                            Number(item.total_buy_quantity) - Number(item.total_sell_quantity) != 0 &&
                                            <Pressable style={[styles.card, { width: '95%' }]}>
                                                {/* <View style={styles.cardTopView}>
                                                <Text style={styles.cardTitle}>{item.name} (<Text style={styles.expiry}>{item.expiry && `${moment(item.expiry).format('DDMMM')}`}</Text>)</Text>
                                                <View style={styles.priceView}>
                                                    <View style={[styles.priceTag, {
                                                        backgroundColor: item.bc == 'black' ? WHITE : item.bc
                                                    }]}>
                                                        <Text style={[styles.price, { color: (item.bc == 'black' && item.b) ? BLACK : (item.bc && item.b) ? WHITE : BLACK }]}>{item.b}</Text>
                                                    </View>
                                                    <View style={[styles.priceTag, {
                                                        backgroundColor: item.ac == 'black' ? WHITE : item.ac,
                                                        marginLeft: 10
                                                    }]}>
                                                        <Text style={[styles.price, { color: (item.ac == 'black' && item.a) ? 'black' : (item.ac && item.a) ? 'white' : 'black' }]}>{item.a}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={styles.cardBottomView}>
                                                <Text style={styles.hl}>H: {item.h}   L: {item.l}</Text>
                                                <View style={styles.percentageView}>
                                                    <Text style={[styles.percentage, { color: item.n > 0 ? GREEN : RED }]}>{item.n && item.n.toFixed(2)} ({item.ch && item.ch.toFixed(2)}%)</Text>
                                                    {item.n > 0 ? <UpIcon style={styles.upDownIcon} /> : <DownIcon style={styles.upDownIcon} />}
                                                </View>
                                            </View> */}
                                                <View>
                                                    {
                                                        // !isEdit &&

                                                    }
                                                    <Text style={styles.cardTitle}>{item.name}-{item.expiry && `${moment(item.expiry).format('MMM').toUpperCase()}`}</Text>
                                                    <View style={{ flexDirection: 'row', marginLeft: 8, marginBottom: 8 }}>
                                                        <Text style={styles.expiry}>{item.expire_new && `${moment(item.expire_new).format('DDMMM')}`}</Text>
                                                        {Number(item.total_buy_quantity) - Number(item.total_sell_quantity) != 0 && <View style={styles.quantityView}>
                                                            <BriefcaseIcon />
                                                            <Text style={{ color: (Number(item.total_buy_quantity) - Number(item.total_sell_quantity) > 0 ? 'green' : (Number(item.total_buy_quantity) - Number(item.total_sell_quantity)) == 0 ? 'black' : 'red'), fontFamily: 'roboto-medium', fontSize: 11 }}>{Number(item.total_buy_quantity) - Number(item.total_sell_quantity) > 0 ? "+" : ''} {Number(item.total_buy_quantity) - Number(item.total_sell_quantity)}</Text>
                                                        </View>}
                                                    </View>
                                                </View>
                                                <View style={{ marginTop: 5 }}>

                                                    <View style={styles.priceView}>
                                                        <View style={[styles.priceTag]}>
                                                            <Text style={[styles.price, { color: BLACK }]}>{item.ltp}</Text>
                                                        </View>
                                                    </View>
                                                    <View style={styles.percentageView}>
                                                        <Text style={[styles.percentage, { color: item.n > 0 ? GREEN : RED }]}>{item.n && item.n.toFixed(2)} ({item.ch && item.ch.toFixed(2)}%)</Text>
                                                        {item.n > 0 ? <UpIcon style={styles.upDownIcon} /> : <DownIcon style={styles.upDownIcon} />}
                                                    </View>
                                                </View>
                                            </Pressable>
                                        }

                                    </View>
                                }
                                keyExtractor={item => item.id}
                                refreshControl={
                                    <RefreshControl refreshing={isLoading} enabled={true} onRefresh={() => getData(selectedSegment)} />
                                }
                            />
                        </View>
                    }
                </ScrollView>
            </SafeAreaView>
        </>
    )
}

export default Home;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: WHITE
    },
    topView: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    nameView: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    name: {
        color: BLACK,
        fontFamily: 'Roboto-Medium',
        fontSize: 14,
        marginLeft: 17,
        marginTop: 10
    },
    hamburgerIcon: {
        height: 20,
        width: 20
    },
    profileBackIcon: {
        marginLeft: 20,
        backgroundColor: LIGHT_GREY,
        borderRadius: 8,
        padding: 13,
        marginTop: 10
    },
    marginView: {
        backgroundColor: PRIMARY,
        marginRight: 30,
        marginLeft: 30,
        marginTop: 15,
        borderRadius: 30
    },
    margin: {
        flexDirection: 'row',
        alignItems: 'center',
        // justifyContent: 'center',
        marginLeft: 40,
        marginRight: 35
    },
    totalMarginText: {
        color: WHITE,
        marginTop: 20,
        fontSize: 14,
        fontFamily: 'Roboto-Medium',
        textAlign: 'center'
    },
    marginAmount: {
        color: WHITE,
        fontSize: 20,
        marginTop: 12,
        fontFamily: 'Roboto-Bold',
        textAlign: 'center'
    },
    profitView: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 22,
        justifyContent: 'space-between'
    },
    totalProfitText: {
        color: WHITE,
        fontSize: 13,
        fontFamily: 'Roboto-Medium',
        marginLeft: 40
    },
    profitAmount: {
        color: WHITE,
        marginRight: 30,
        fontSize: 16,
        fontFamily: 'Roboto-Bold'
    },
    indexText: {
        color: BLACK,
        marginLeft: 30,
        marginTop: 12,
        fontSize: 18,
        fontFamily: 'Roboto-Medium'
    },
    indexView: {
        width: 150,
        borderWidth: 1,
        borderColor: GREY,
        borderRadius: 15,
        marginTop: 20,
        shadowOpacity: 0.26,
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 10,
        elevation: 4,
        backgroundColor: WHITE,
        shadowColor: 'rgba(0, 0, 0, 0.25)'
    },
    indexs: {
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    indexTitle: {
        color: BLACK,
        fontFamily: 'Roboto-Bold',
        marginLeft: 18,
        marginTop: 15,
        fontSize: 15
    },
    indexValue: {
        color: BLACK,
        marginLeft: 18,
        marginTop: 7,
        fontSize: 18,
        fontFamily: 'Roboto-Bold'
    },
    indexPercentage: {
        flexDirection: 'row',
        marginLeft: 18,
        marginRight: 18,
        marginTop: 12,
        marginBottom: 10,
        justifyContent: 'space-between'
    },
    indexPercentageText: {
        color: GREEN,
        fontSize: 12,
        fontFamily: 'Roboto-Bold'
    },
    card: {
        borderWidth: 1,
        borderColor: GREY,
        marginLeft: 10,
        marginRight: 10,
        marginTop: 8,
        borderRadius: 12,
        shadowOpacity: 0.26,
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 10,
        elevation: 4,
        backgroundColor: WHITE,
        shadowColor: 'rgba(0, 0, 0, 0.25)',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    cardTitle: {
        color: BLACK,
        fontFamily: 'Roboto-Medium',
        marginTop: 12,
        marginLeft: 8,
        fontSize: 16
    },
    cardTopView: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: 20
    },
    priceView: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        alignSelf: 'flex-end',
        marginRight: 20
        // marginTop: 12,
    },
    priceTag: {
        borderRadius: 5,
        // width: '50%',
        marginTop: 4,
    },
    price: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 5,
        paddingRight: 5,
        color: WHITE,
        fontFamily: 'Roboto-Bold',
        fontSize: 18
    },
    cardBottomView: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 7,
        marginBottom: 6,
        justifyContent: 'space-between'
    },
    expiry: {
        color: BLACK,
        // marginLeft: 16,
        fontFamily: 'Roboto-Bold',
        fontSize: 12
    },
    percentage: {
        color: GREEN,
        fontFamily: 'Roboto-Bold',
        fontSize: 11
    },
    upDownIcon: {
        marginRight: 20
    },
    percentageView: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-end'
        // marginTop: 10,
        // marginLeft: 8
    },
    hl: {
        color: BLACK,
        // marginLeft: 10,
        fontFamily: 'Roboto-Medium',
        fontSize: 13,
        textAlign: 'right',
        left: 10
    },
    quantityView: {
        flexDirection: 'row',
        alignItems: 'center',
        // marginLeft: 8,
        // paddingTop: 3,
        // marginBottom: 4
    },
    marginViewNSE: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 40,
        marginRight: 40
    },
    marginTitle: {
        color: WHITE,
        fontSize: 12,
        fontFamily: 'roboto-regular',
        marginTop: 3
    },
    marginValue: {
        color: WHITE,
        fontFamily: 'roboto-medium',
        textAlign: 'right',
        marginLeft: 10
    },
    marginText: {
        color: WHITE,
        fontSize: 13,
        fontFamily: 'roboto-bold',
        marginLeft: 40,
        marginTop: 10,
        textAlign: 'left'
    }
})