import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text, Image, Pressable, FlatList, ScrollView, ActivityIndicator, Linking, SafeAreaView } from 'react-native';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import moment from 'moment';
import pdfIcon from '../../Assets/Images/pdfIcon.png';
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';
import LeftArrow from '../../Assets/Images/arrow-left.svg';
import { BLACK, DARK_GREY, GREY, PRIMARY, RED, WHITE } from '../../Config/colors';

const LedgerDetail = ({ navigation, route, props }) => {
    const dispatch = useDispatch()
    const { data } = route.params
    const [reportData, setReportData] = useState([])
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        getDetail()
    }, [])

    const getDetail = () => {
        setLoading(true)
        axios.get(`${BASE_URL}/user-settle-trades-list?id=${data.id}`)
            .then((res) => {
                console.log("Res", res.data)
                res.list = res.data.list
                var tempList = []
                for (var i in res.list) {
                    res.list[i].datatype = 'DATA'
                    var k = -1;
                    var net_price = Math.abs((res.list[i].net_price * res.list[i].quantity) - (res.list[i].price * res.list[i].quantity))
                    for (var j in tempList) {
                        if (tempList[j].trading_symbol == res.list[i].trading_symbol) {
                            k = j;
                        }
                    }

                    var netamount = (res.list[i].net_price * res.list[i].quantity);
                    if (res.list[i].order_action == "BUY") {
                        netamount = -netamount;
                    }
                    if (k == -1) {
                        var temp = {
                            trading_symbol: res.list[i].trading_symbol, list: [res.list[i]], bq: res.list[i].order_action == "BUY" ? res.list[i].quantity : 0, sq: res.list[i].order_action == "SELL" ? res.list[i].quantity : 0, price: res.list[i].order_action == "BUY" ? -res.list[i].price * res.list[i].quantity : res.list[i].price * res.list[i].quantity, net_price: net_price, netamount: netamount
                        };
                        tempList.push(temp);
                    } else {
                        tempList[k].bq += res.list[i].order_action == "BUY" ? res.list[i].quantity : 0;
                        tempList[k].sq += res.list[i].order_action == "SELL" ? res.list[i].quantity : 0;
                        if (res.list[i].order_action == "SELL") {
                            tempList[k].price += res.list[i].price * res.list[i].quantity;
                        } else {
                            tempList[k].price -= res.list[i].price * res.list[i].quantity;
                        }
                        tempList[k].net_price += net_price;
                        tempList[k].netamount += netamount;
                        tempList[k].list.push(res.list[i]);
                    }

                }
                console.log("temo", tempList)

                for (var m in tempList) {

                    var obj = {
                        datatype: 'Total',
                        title: 'Script Wise total:',
                        bq: tempList[m].bq,
                        sq: tempList[m].sq,
                        price: tempList[m].price,
                        net_price: tempList[m].net_price,
                        netamount: tempList[m].netamount,
                    }

                    tempList[m].list.push(obj);

                }
                setReportData(tempList)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const onDownload = () => {
        axios.get(`${BASE_URL}/get-url`)
            .then((res) => {
                Linking.openURL(`${res.data}/${data.id}`);
            })
            .catch((e) => {
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })

    }

    return (
        <>
            <SafeAreaView style={styles.container}>
                <View style={styles.header}>
                    <View style={styles.nameView}>
                        <Pressable onPress={() => navigation.goBack()} hitSlop={styles.hit}>
                            <LeftArrow style={styles.leftIcon} />
                        </Pressable>
                        <Text style={styles.rejectionReportText}>Ledger Detail Report</Text>
                    </View>
                    <Pressable onPress={() => onDownload()}>
                        <Image source={pdfIcon} style={{ height: 25, width: 25 }} />
                    </Pressable>
                </View>
                <Text style={styles.title}>{data.remark}</Text>
                <ScrollView>
                    {
                        reportData && reportData.length > 0 && reportData.map((res) => {
                            return (
                                <View>
                                    <Text style={styles.label}>{res.trading_symbol}</Text>
                                    <View style={styles.tableView}>
                                        <View style={styles.tableHeader}>
                                            <Text style={styles.tableHeaderTitle}>Date Time</Text>
                                            <Text style={[styles.tableHeaderTitle, { width: '6%' }]}>Type</Text>
                                            <Text style={styles.tableHeaderTitle}>Lot</Text>
                                            <Text style={[styles.tableHeaderTitle]}>Buy Qty</Text>
                                            <Text style={styles.tableHeaderTitle}>Sell Qty</Text>
                                            <Text style={styles.tableHeaderTitle}>Rate</Text>
                                            <Text style={styles.tableHeaderTitle}>Net Rate</Text>
                                            <Text style={styles.tableHeaderTitle}>Amt</Text>
                                        </View>
                                        {
                                            res.list && res.list?.length > 0 && <FlatList
                                                data={res?.list}
                                                renderItem={({ item, j }) => <View style={[styles.tableContent, { borderBottomWidth: j == res.list.length - 1 ? 0 : 1 }]} key={new Date()}>
                                                    <Text style={[styles.tableItem, { borderLeftWidth: 1 }]}>{item?.datatype != 'Total' && (item?.created_at && moment(item?.created_at).format('DD-MM-YY HH:mm'))}</Text>
                                                    <Text style={[styles.tableItem, { width: '7%' }]}>{item?.datatype != 'Total' && (item?.type == 'NORMAL' ? 'NRM' : item?.type)}</Text>
                                                    <Text style={styles.tableItem}>{item?.datatype != 'Total' && item?.lot.toFixed(2)}</Text>
                                                    <Text style={[styles.tableItem, { color: item.order_action == 'BUY' ? '#2E2EFF' : BLACK }]}>{item?.datatype != 'Total' ? (item?.order_action == 'BUY' ? item?.quantity : 0) : item.bq}</Text>
                                                    <Text style={[styles.tableItem, { color: item.order_action == 'SELL' ? RED : BLACK }]}>{item?.datatype != 'Total' ? (item?.order_action == 'SELL' ? item?.quantity : 0) : item?.sq}</Text>
                                                    <Text style={styles.tableItem}>{item?.price && item?.price.toFixed(2)}</Text>
                                                    <Text style={styles.tableItem}>{item?.net_price && item?.net_price.toFixed(2)}</Text>
                                                    <Text style={[styles.tableItem, { color: item.datatype != 'Total' ? (item.order_action == 'SELL' ? RED : item.order_action == 'BUY' ? '#2E2EFF' : BLACK) : item.netamount > 0 ? '#2E2EFF' : RED }]}>{item?.datatype != 'Total' ? (item?.order_action == 'SELL' ? '-' + (item?.net_price * item?.quantity).toFixed(2) : (item?.net_price * item?.quantity).toFixed(2)) : item?.netamount.toFixed(2)}</Text>
                                                </View>}
                                                keyExtractor={item => item.id}
                                            />
                                        }
                                    </View>
                                </View>
                            )
                        })
                    }
                </ScrollView>
                {
                    !isLoading && reportData?.length == 0 &&
                    <View style={styles.loaderView}>
                        <Text style={styles.errorMessage}>No Data Found!</Text>
                    </View>
                }
                {isLoading && reportData.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color={BLACK} />
                </View>}
            </SafeAreaView>
        </>
    )
}

export default LedgerDetail;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: WHITE
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: 20
    },
    leftIcon: {
        marginLeft: 28,
        marginTop: 20
    },
    rejectionReportText: {
        fontSize: 20,
        marginTop: 20,
        marginLeft: 15,
        color: PRIMARY,
        fontFamily: 'Roboto-Medium'
    },
    hit: {
        top: 20,
        bottom: 20,
        left: 50,
        right: 50
    },
    nameView: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    title: {
        color: BLACK,
        textAlign: 'center',
        marginTop: 10,
        fontFamily: 'Roboto-Bold',
        fontSize: 17
    },
    label: {
        color: BLACK,
        fontFamily: 'Roboto-Medium',
        textAlign: 'center',
        marginTop: 20
    },
    tableView: {
        marginTop: 10,
        marginLeft: 10,
        marginRight: 10,
        borderWidth: 1,
        padding: 8,
        borderRadius: 10,
        borderColor: DARK_GREY
    },
    tableHeader: {
        width: '100%',
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: GREY,
        backgroundColor: GREY,
        // borderRadius: 50
    },
    tableHeaderTitle: {
        fontSize: 9,
        fontFamily: 'Roboto-Bold',
        width: '14%',
        textAlign: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        color: BLACK,
    },
    tableContent: {
        flexDirection: 'row',
        width: '100%',
        borderBottomWidth: 1,
        borderColor: GREY
    },
    tableItem: {
        fontSize: 12,
        fontFamily: 'Roboto-Medium',
        textAlign: 'center',
        width: '14%',
        paddingTop: 5,
        paddingBottom: 5,
        color: BLACK,
        borderRightWidth: 1,
        borderColor: GREY
    },
    loaderView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    errorMessage: {
        color: BLACK,
        fontFamily: 'Roboto-Medium'
    }
})