import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet, View, Pressable, Text, TouchableOpacity, TouchableHighlight } from 'react-native';
import { Image } from 'expo-image';
import { Secondary, Width } from "../../Utility/Utility";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SwipeListView, SwipeRow } from 'react-native-swipe-list-view';
import { useTheme } from "../../Contexts/ThemeProvider";
import loginIcon from '../../Assets/Images/loginIcon.png';
import loginIconBlue from '../../Assets/Images/loginBlue.png';
import userIcon from '../../Assets/Images/addUserBlue.png';
import AddUser from '../../Assets/Images/add-user.svg';
import LoginIcon from '../../Assets/Images/login.svg';
import { BLACK, GREY, PRIMARY, WHITE } from "../../Config/colors";
import { useFonts } from 'expo-font';

const LoginUsers = (props) => {
    const { dark, colors } = useTheme();
    const [users, setUsers] = useState([])
    const [startDate, setStartDate] = useState(new Date());
    const [fontsLoaded] = useFonts({
        'Roboto-Medium': require('../../Assets/Fonts/Roboto-Medium.ttf'),
        'Roboto-Bold': require('../../Assets/Fonts/Roboto-Bold.ttf')
    });

    const onLayoutRootView = useCallback(async () => {
    }, [fontsLoaded]);

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        await AsyncStorage.getItem('login_users')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data == null || data.length == 0) {
                    props.navigation.navigate('Login', { data: null, isSelect: false })
                }
                for (var i in data) {
                    data[i].key = i
                }
                console.log("data", data)
                setUsers(data)
            })
            .catch(error => {
                props.navigation.navigate('Login', { data: null, isSelect: false })
            });
    }

    const onClearStorage = async () => {
        await AsyncStorage.clear();
        props.navigation.navigate('Login', { data: null, isSelect: false })
    }

    const closeRow = (rowMap, rowKey) => {
        if (rowMap[rowKey]) {
            rowMap[rowKey].closeRow();
        }
    };

    const deleteRow = async (rowMap, rowKey) => {
        closeRow(rowMap, rowKey);
        const newData = [...users];
        const prevIndex = users.findIndex(item => item.key === rowKey);
        newData.splice(prevIndex, 1);
        setUsers(newData);
        const logData = JSON.stringify(newData)
        await AsyncStorage.setItem('login_users', logData)
        if (newData.length == 0) {
            props.navigation.navigate('Login', { data: null, isSelect: false })
        }
    };

    const onRowDidOpen = rowKey => {
        // console.log('This row opened', rowKey);
    };

    const renderItem = data => (
        <TouchableHighlight
            onPress={() => props.navigation.navigate('Login', { data: data.item, isSelect: true })}
            style={styles.rowFront}
            underlayColor={'#AAA'}
        >
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: Width * 0.8, alignItems: 'center', }}>
                <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontWeight: 'bold', color: BLACK, fontSize: 25, textTransform: 'uppercase' }}>{data.item.name}
                    </Text>
                    <Text style={{ fontSize: 17, marginTop: 10, marginLeft: 10, fontWeight: 'bold' }}>({data.item.code})</Text>
                </View>
                {/* <Image source={loginIconBlue} style={{ height: 32, width: 32 }} /> */}
                <LoginIcon width={22} height={22} fill={PRIMARY} />
            </View>
        </TouchableHighlight>
    );

    const renderHiddenItem = (data, rowMap) => (
        <View style={styles.rowBack}>
            <Text>Left</Text>
            <TouchableOpacity
                style={[styles.backRightBtn, styles.backRightBtnLeft]}
                onPress={() => closeRow(rowMap, data.item.key)}
            >
                <Text style={styles.backTextWhite}>Close</Text>
            </TouchableOpacity>
            <TouchableOpacity
                style={[styles.backRightBtn, styles.backRightBtnRight]}
                onPress={() => deleteRow(rowMap, data.item.key)}
            >
                <Text style={styles.backTextWhite}>Delete</Text>
            </TouchableOpacity>
        </View>
    );

    return (
        <>
            <View style={[styles.container, { backgroundColor: colors.Primary }]} onLayout={onLayoutRootView}>
                {
                    users?.length > 0 && <View>
                        <View style={[styles.header, { borderColor: colors.Secondary }]}>
                            <View style={styles.titleView}>
                                <View style={{ width: '90%', justifyContent: 'center', alignItems: 'center', marginLeft: 10 }}>
                                    <Text style={[styles.headerTitle, { color: colors.Secondary }]}>Logged Users</Text>
                                </View>
                                <Pressable style={{ width: '10%' }} onPress={() => props.navigation.navigate('Login', { data: null, isSelect: false })}>
                                    {/* <Image source={userIcon} style={{ height: 25, width: 25 }} /> */}
                                    <AddUser width={30} height={30} fill={PRIMARY} />
                                </Pressable>
                            </View>
                        </View>
                        {
                            users?.length > 0 &&
                            <SwipeListView
                                data={users}
                                renderItem={renderItem}
                                renderHiddenItem={renderHiddenItem}
                                disableRightSwipe={true}
                                rightOpenValue={-150}
                                previewRowKey={'0'}
                                previewOpenValue={-40}
                                previewOpenDelay={3000}
                                onRowDidOpen={onRowDidOpen}
                            />
                        }
                    </View>
                }
            </View>
        </>
    )
}

export default LoginUsers;

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    header: {
        paddingLeft: 25,
        marginTop: 10,
        borderBottomWidth: 1,
        flexDirection: 'row',
        paddingRight: 25,
        height: 40
    },
    titleView: {
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row'
    },
    headerTitle: {
        fontSize: 16,
        fontFamily: 'Roboto-Medium'
    },
    code: {
        fontSize: 14,
        fontWeight: '500'
    },
    codeView: {
        borderBottomWidth: 1,
        padding: 10,
        marginLeft: 20,
        marginRight: 20
    },
    button: {
        borderWidth: 1,
        width: '45%',
        padding: 10,
        borderRadius: 10,
        alignItems: 'center'
    },
    buttonText: {
        fontWeight: '500'
    },
    rowBack: {
        // backgroundColor: 'red'
    },
    backTextWhite: {
        color: '#FFF',
    },
    rowFront: {
        alignItems: 'center',
        backgroundColor: WHITE,
        justifyContent: 'center',
        // height: 50,
        paddingLeft: 30,
        borderWidth: 1,
        borderColor: GREY,
        paddingTop: 10,
        paddingBottom: 10
    },
    rowBack: {
        alignItems: 'center',
        backgroundColor: '#DDD',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 15,
    },
    backRightBtn: {
        alignItems: 'center',
        bottom: 0,
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        width: 75,
    },
    backRightBtnLeft: {
        backgroundColor: 'blue',
        right: 75,
    },
    backRightBtnRight: {
        backgroundColor: 'red',
        right: 0,
    },
})