import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Pressable, Text, ActivityIndicator, FlatList, TextInput, Modal, SafeAreaView } from 'react-native';
import { Width } from '../../Utility/Utility';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import { useIsFocused } from "@react-navigation/native";
import { DrawerActions } from '@react-navigation/native';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RefreshControl } from 'react-native-web-refresh-control'
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';
import HamburgerIcon from '../../Assets/Images/hamburger.svg';
import { BLACK, DARK_GREY, GREY, LAVENDER_MIST, LIGHT_GREY, PRIMARY, RED, SOFT_PEACH, WHITE, ZAMBEZI } from '../../Config/colors';
import FilterIcon from '../../Assets/Images/filter.svg'

const Trades = (props) => {
    const dispatch = useDispatch()
    const [selectedSegment, setSelectedSegment] = useState('NFO')
    const [allTrades, setAllTrades] = useState([])
    const userId = useRef('')
    const [isLoading, setLoading] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [modalVisible, setModalVisible] = useState(false);
    const [date, setDate] = useState(new Date())
    const [enddate, setEndDate] = useState(new Date())
    const isFocused = useIsFocused();


    const _onMenuPress = () => {
        props.navigation.dispatch(DrawerActions.openDrawer());
    }


    useEffect(() => {
        getData()
    }, [isFocused])

    const getData = async () => {
        try {
            const jsonValue = await AsyncStorage.getItem('user_detail')
            const data = jsonValue != null ? JSON.parse(jsonValue) : null;
            userId.current = data.id
            checkTab()
        } catch (e) {
            // error reading value
        }
    }

    const checkTab = async () => {
        await AsyncStorage.getItem('tab')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setSelectedSegment(data.currenTab)
                    getTrades(data.currenTab)
                } else {
                    getTrades('NFO')
                }
            })
            .catch(error => {
                getTrades('NFO')
            });
    }

    const getTrades = (value, search, fromDate, toDate) => {
        var fDate = fromDate ? moment(fromDate).format('YYYY-MM-DD') : ''
        var tDate = toDate ? moment(toDate).format('YYYY-MM-DD') : ''
        setLoading(true)
        if (fDate) {
            var url = `${BASE_URL}/trade-list?exchange=${value == 'ALL' ? '' : value}&trade_status=EXECUTED&user_id=${userId.current}&keyword=${search ? search : ''}&from_date=${fDate}&to_date=${tDate}&sort_by=trade_date&order_by=DESC`
        } else {
            var url = `${BASE_URL}/trade-list?exchange=${value == 'ALL' ? '' : value}&trade_status=EXECUTED&user_id=${userId.current}&day=${fDate ? '' : 'TODAY'}&keyword=${search ? search : ''}&from_date=${fDate}&to_date=${tDate}&sort_by=trade_date&order_by=DESC`
        }
        axios.get(url)
            .then((res) => {
                setAllTrades(res.data)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const onChangeTab = async (value) => {
        const data = JSON.stringify({ currenTab: value })
        await AsyncStorage.setItem('tab', data)
        setAllTrades([])
        setSelectedSegment(value)
        getTrades(value)
    }

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button style={{ color: BLACK, borderRadius: 12, height: 45, width: Width * 0.9, backgroundColor: WHITE, borderColor: PRIMARY }} onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    return (
        <>
            <SafeAreaView style={styles.container}>
                <View style={styles.topView}>
                    <View style={styles.nameView}>
                        <Pressable onPress={() => _onMenuPress()} style={styles.profileBackIcon}>
                            <HamburgerIcon style={styles.hamburgerIcon} />
                        </Pressable>
                        <Text style={styles.name}>Hi, {global.name}</Text>
                    </View>
                    <Pressable hitSlop={styles.hit} onPress={() => setModalVisible(true)}>
                        <FilterIcon style={styles.filterIcon} />
                    </Pressable>
                </View>
                <View style={styles.searchBox}>
                    <TextInput
                        placeholder="Search script"
                        placeholderTextColor={BLACK}
                        style={styles.search}
                        value={searchText}
                        onChangeText={text => { setSearchText(text), getTrades(selectedSegment, text) }} />
                </View>
                <View style={styles.tagView}>
                    <Pressable style={[styles.tag, {
                        backgroundColor: selectedSegment == "ALL" ? PRIMARY : LAVENDER_MIST,
                        marginLeft: 20
                    }]} onPress={() => onChangeTab('ALL')} hitSlop={styles.hit}>
                        <Text style={[styles.tagText, {
                            color: selectedSegment == "ALL" ? WHITE : PRIMARY,
                        }]}>All</Text>
                    </Pressable>
                    <Pressable style={[styles.tag, {
                        backgroundColor: selectedSegment == "NFO" ? PRIMARY : LAVENDER_MIST,
                        marginLeft: 17
                    }]} onPress={() => onChangeTab('NFO')} hitSlop={styles.hit}>
                        <Text style={[styles.tagText, {
                            color: selectedSegment == "NFO" ? WHITE : PRIMARY,
                        }]}>NSE</Text>
                    </Pressable>
                    <Pressable style={[styles.tag, {
                        backgroundColor: selectedSegment == "MCX" ? PRIMARY : LAVENDER_MIST,
                        marginLeft: 17
                    }]} onPress={() => onChangeTab('MCX')} hitSlop={styles.hit}>
                        <Text style={[styles.tagText, {
                            color: selectedSegment == "MCX" ? WHITE : PRIMARY,
                        }]}>MCX</Text>
                    </Pressable>
                </View>
                {!isLoading && allTrades.length > 0 &&
                    <FlatList
                        data={allTrades}
                        renderItem={({ item, index }) => <View style={[styles.card, { marginTop: index == 0 ? 20 : 8, backgroundColor: item.order_action == 'SELL' ? "#edd3d3" : '#d9f2d9' }]}>
                            <View style={styles.cardTopView}>
                                <View style={styles.stockNameView}>
                                    <Text style={styles.stockName}>{item.trading_symbol}</Text>
                                    {/* <Text style={styles.expiry}> {item.script?.expiry && moment(item.script?.expiry).format('DD/MM/YYYY HH:mm:ss')}</Text> */}
                                </View>
                                <View>
                                    <Text style={styles.date}>{item.trade_date && moment(item.trade_date).format('DD/MM/YYYY HH:mm:ss')}</Text>
                                </View>
                            </View>
                            <View style={styles.cardBottomView}>
                                <View style={styles.stockNameView}>
                                    <Text style={[styles.type, { color: item.order_action == 'SELL' ? RED : '#1034A6' }]}>{item.order_type != 'INTRADAY' && item.order_action}</Text>
                                    <Text style={[styles.subType, { color: item.order_action == 'SELL' ? RED : '#1034A6' }]}> {item.order_type == 'LIMIT' ? 'Limit' : item.order_type == 'STOPLOSS' ? 'StopLoss' : item.order_type == 'INTRADAY' ? 'Intraday Close Limit' : ' '}</Text>
                                </View>
                                {item.order_type != 'INTRADAY' && <View>
                                    <Text style={styles.price}>{item.price && (item.price.toFixed(2))}</Text>
                                </View>}
                                {item.order_type != 'INTRADAY' && <View>
                                    <Text style={styles.qty}>Qty: {item.quantity} ({item.lot && item.lot.toFixed(2)})</Text>
                                </View>}
                            </View>
                        </View>}
                        keyExtractor={item => item.id}
                        refreshControl={
                            <RefreshControl refreshing={isLoading} onRefresh={() => getTrades(selectedSegment)} />
                        }
                    />
                }
                {isLoading && allTrades.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color={BLACK} />
                </View>}
                {!isLoading && allTrades.length == 0 && <View style={styles.loaderView}>
                    <Text style={styles.errorMessage}>No Trades Found!</Text>
                </View>}
            </SafeAreaView>
            <Modal animationType="slide" transparent={true} visible={modalVisible}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <Text style={styles.filterTitle}>Filter</Text>
                        <Text style={styles.label}>From Date</Text>
                        <DatePicker selected={date} onChange={(date) => setDate(date)} customInput={<ExampleCustomInput />} />
                        <Text style={styles.label}>To Date</Text>
                        <DatePicker selected={enddate} onChange={(date) => setEndDate(date)} customInput={<ExampleCustomInput />} />
                        <View style={styles.buttonView}>
                            <Pressable style={[styles.saveButton, { backgroundColor: WHITE, borderColor: PRIMARY, borderWidth: 1, marginRight: 20 }]} onPress={() => {
                                setDate(new Date())
                                setEndDate(new Date())
                                setModalVisible(!modalVisible)
                                getTrades(selectedSegment, searchText, '', '')
                            }}>
                                <Text style={[styles.saveButtonText, { color: PRIMARY }]}>Reset</Text>
                            </Pressable>
                            <Pressable style={[styles.saveButton, { backgroundColor: WHITE, borderColor: PRIMARY, borderWidth: 1, marginRight: 20 }]} onPress={() => setModalVisible(!modalVisible)}>
                                <Text style={[styles.saveButtonText, { color: PRIMARY }]}>Cancel</Text>
                            </Pressable>
                            <Pressable style={styles.saveButton} onPress={() => { setModalVisible(false), getTrades(selectedSegment, searchText, date, enddate) }}>
                                <Text style={styles.saveButtonText}>Apply</Text>
                            </Pressable>
                        </View>
                    </View>
                </View>
            </Modal>
        </>
    )
}

export default Trades;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: WHITE
    },
    topView: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    nameView: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    name: {
        color: BLACK,
        fontFamily: 'Roboto-Medium',
        fontSize: 14,
        marginLeft: 17,
        marginTop: 10
    },
    hamburgerIcon: {
        height: 20,
        width: 20
    },
    profileBackIcon: {
        marginLeft: 20,
        backgroundColor: LIGHT_GREY,
        borderRadius: 8,
        padding: 13,
        marginTop: 10
    },
    tagView: {
        flexDirection: 'row'
    },
    tag: {
        borderRadius: 35,
        marginTop: 25
    },
    tagText: {
        fontFamily: 'Roboto-Medium',
        fontSize: 11,
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 16,
        paddingLeft: 16
    },
    hit: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20
    },
    card: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 15,
        backgroundColor: SOFT_PEACH,
        borderRadius: 12
    },
    cardTopView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 7,
        marginLeft: 12,
        marginRight: 12
    },
    stockName: {
        color: BLACK,
        fontSize: 15,
        fontFamily: 'Roboto-Medium'
    },
    stockNameView: {
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    expiry: {
        color: DARK_GREY,
        fontSize: 12,
        fontFamily: 'Roboto-Bold'
    },
    cardBottomView: {
        marginTop: 8,
        marginBottom: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 12,
        marginRight: 12
    },
    price: {
        color: BLACK,
        fontSize: 16,
        fontFamily: 'Roboto-Bold'
    },
    qty: {
        color: BLACK,
        fontSize: 12,
        fontFamily: 'Roboto-Medium'
    },
    type: {
        color: RED,
        fontSize: 13,
        fontFamily: 'Roboto-Bold'
    },
    date: {
        color: DARK_GREY,
        fontSize: 12,
        fontFamily: 'Roboto-Medium'
    },
    loaderView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    errorMessage: {
        color: BLACK,
        fontFamily: 'Roboto-Medium'
    },
    subType: {
        color: RED,
        fontSize: 9,
        fontFamily: 'Roboto-Bold'
    },
    searchBox: {
        backgroundColor: GREY,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 15,
        borderRadius: 53,
        flexDirection: 'row',
        height: 45
    },
    search: {
        paddingLeft: 28,
        width: '100%'
    },
    filterIcon: {
        marginRight: 30
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 10,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    filterTitle: {
        fontSize: 15,
        color: BLACK,
        fontFamily: 'Roboto-Medium',
        marginLeft: 5,
        marginTop: 10
    },
    label: {
        color: ZAMBEZI,
        marginBottom: 5,
        marginTop: 10,
        fontFamily: 'Roboto-Medium',
        fontSize: 13
    },
    saveButton: {
        backgroundColor: PRIMARY,
        alignSelf: 'center',
        marginTop: 20,
        borderRadius: 16
    },
    saveButtonText: {
        color: WHITE,
        fontSize: 20,
        fontFamily: 'Roboto-Bold',
        marginTop: 10,
        marginBottom: 9,
        marginLeft: 20,
        marginRight: 20
    },
    buttonView: {
        flexDirection: 'row'
    }
})