import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, Pressable, Switch, SafeAreaView } from 'react-native';
import { useTheme } from "../../Contexts/ThemeProvider";
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';
import { BLACK, LAVENDER_MIST, PRIMARY, SECONDARY, WHITE } from '../../Config/colors';
import LeftArrow from '../../Assets/Images/arrow-left.svg';

const Setting = (props) => {
    const dispatch = useDispatch()
    const { dark, colors } = useTheme();
    const _onBack = () => props.navigation.goBack()
    const [isSingleTrade, setIsSingleTrade] = useState(false);
    const [isSoundAlert, setIsSoundAlert] = useState(false);
    const [isNotification, setIsNotification] = useState(false);

    useEffect(() => {
        check()
        getUserData()
    }, [])

    const check = async () => {
        await AsyncStorage.getItem('TRADE')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setIsSingleTrade(data)
                } else {
                }
            })
            .catch(error => {
            });
        await AsyncStorage.getItem('SOUND')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setIsSoundAlert(data)
                } else {
                }
            })
            .catch(error => {
            });
        await AsyncStorage.getItem('NOTIFICATION')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setIsNotification(data)
                    getUserData()
                } else {
                }
            })
            .catch(error => {
            });
    }

    const getUserData = () => {
        axios.get(`${BASE_URL}/me`)
            .then((res) => {
                console.log("Res", res.data)
                if (res.data) {
                    setIsSingleTrade(res?.data?.is_trade == 0 ? false : true)
                    setIsSoundAlert(res?.data?.is_sound == 0 ? false : true)
                    setIsNotification(res?.data?.is_notification == 0 ? false : true)
                }
            })
            .catch((e) => {
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const toggleSwitch = async (value) => {
        if (value == 'TRADE') {
            setIsSingleTrade(!isSingleTrade)
            try {
                const jsonValue = JSON.stringify(!isSingleTrade)
                await AsyncStorage.setItem('TRADE', jsonValue)
            } catch (e) {
                // saving error
            }
        } else if (value == 'SOUND_ALERT') {
            setIsSoundAlert(!isSoundAlert)
            try {
                const jsonValue = JSON.stringify(!isSoundAlert)
                await AsyncStorage.setItem('SOUND', jsonValue)
            } catch (e) {
                // saving error
            }
        } else if (value == 'NOTIFICATION') {
            setIsNotification(!isNotification)
            try {
                const jsonValue = JSON.stringify(!isSoundAlert)
                await AsyncStorage.setItem('NOTIFICATION', jsonValue)
            } catch (e) {
                // saving error
            }
        }
        console.log("adf", {
            is_trade: value == 'TRADE' ? !isSingleTrade : isSingleTrade,
            is_sound: value == 'SOUND_ALERT' ? !isSoundAlert : isSoundAlert,
            is_notification: value == 'NOTIFICATION' ? !isNotification : isNotification
        })
        axios.put(`${BASE_URL}/update-app-setting`, {
            is_trade: value == 'TRADE' ? !isSingleTrade : isSingleTrade,
            is_sound: value == 'SOUND_ALERT' ? !isSoundAlert : isSoundAlert,
            is_notification: value == 'NOTIFICATION' ? !isNotification : isNotification
        })
            .then((res) => {
                // Toast.show({
                //     type: 'success',
                //     text1: res?.data?.message,
                // });
            })
            .catch((e) => {

            })
    }

    return (
        <>
            <SafeAreaView style={styles.container}>
                <View style={styles.header}>
                    <Pressable onPress={() => props.navigation.goBack()} hitSlop={styles.hit}>
                        <LeftArrow style={styles.leftIcon} />
                    </Pressable>
                    <Text style={styles.settingText}>Settings</Text>
                </View>
                <View style={[styles.item, { marginTop: 30 }]}>
                    <Text style={styles.itemTitle}>Single Click Trade</Text>
                    <Switch
                        trackColor={{ false: BLACK, true: SECONDARY }}
                        thumbColor={isSingleTrade ? PRIMARY : '#f4f3f4'}
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={() => toggleSwitch('TRADE')}
                        value={isSingleTrade}
                    />
                </View>
                <View style={[styles.item, { marginTop: 13 }]}>
                    <Text style={styles.itemTitle}>Sound Alert</Text>
                    <Switch
                        trackColor={{ false: BLACK, true: SECONDARY }}
                        thumbColor={isSoundAlert ? PRIMARY : '#f4f3f4'}
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={() => toggleSwitch('SOUND_ALERT')}
                        value={isSoundAlert}
                    />
                </View>
                <View style={[styles.item, { marginTop: 13 }]}>
                    <Text style={styles.itemTitle}>Notifications</Text>
                    <Switch
                        trackColor={{ false: BLACK, true: SECONDARY }}
                        thumbColor={isNotification ? PRIMARY : '#f4f3f4'}
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={() => toggleSwitch('NOTIFICATION')}
                        value={isNotification}
                    />
                </View>
            </SafeAreaView>
        </>
    )
}

export default Setting;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: WHITE
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    leftIcon: {
        marginLeft: 28,
        marginTop: 20
    },
    settingText: {
        fontSize: 20,
        marginTop: 20,
        marginLeft: 15,
        color: PRIMARY,
        fontFamily: 'Roboto-Medium'
    },
    hit: {
        top: 20,
        bottom: 20,
        left: 50,
        right: 50
    },
    item: {
        backgroundColor: LAVENDER_MIST,
        marginLeft: 25,
        marginRight: 25,
        borderRadius: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingRight: 12,
        alignItems: 'center'
    },
    itemTitle: {
        color: PRIMARY,
        marginTop: 19,
        marginBottom: 18,
        marginLeft: 22,
        fontSize: 14,
        fontFamily: 'Roboto-Medium'
    }
})