import moment from 'moment';
import { Dimensions } from 'react-native';
// import Toast from 'react-native-simple-toast';

export const Width = Dimensions.get('window').width;
export const Height = Dimensions.get('window').height;

export const Primary = '#000000'
export const Secondary = '#FFFFFF'
export const color1 = '#787878'
export const color2 = '#404040'
export const color3 = '#0033FF'

export const round = (value) => {
  return Number(Math.round(value + 'e' + 2) + 'e-' + 2).toFixed(2);
}

export const getCustomDate = (date) => {
  return date ? moment(date).format("DDMMM").toLocaleUpperCase() : "-";
}

export const formatter = new Intl.NumberFormat('en-IN', {
  // style: 'currency',
  currency: 'INR',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});