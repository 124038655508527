import { SafeAreaView, View, Pressable, StyleSheet, Text } from 'react-native';
import LeftArrow from '../../Assets/Images/arrow-left.svg';
import { PRIMARY, WHITE } from '../../Config/colors';
import { WebView } from 'react-native-webview';

const TermsAndCondition = (props) => {
    return (
        <>
            <SafeAreaView style={styles.container}>
                <View style={styles.header}>
                    <Pressable onPress={() => props.navigation.goBack()} hitSlop={styles.hit}>
                        <LeftArrow style={styles.leftIcon} />
                    </Pressable>
                    <Text style={styles.termsText}>Terms & Conditions</Text>
                </View>
                <iframe src="https://easytrade9.com/terms" height={'100%'} width={'100%'} style={{ marginTop: 20 }} />
            </SafeAreaView>
        </>
    )
}

export default TermsAndCondition;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: WHITE
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    leftIcon: {
        marginLeft: 28,
        marginTop: 20
    },
    termsText: {
        fontSize: 20,
        marginTop: 20,
        marginLeft: 15,
        color: PRIMARY,
        fontFamily: 'Roboto-Medium'
    },
    hit: {
        top: 20,
        bottom: 20,
        left: 50,
        right: 50
    },
})