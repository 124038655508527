import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, Pressable, FlatList, ActivityIndicator, SafeAreaView } from 'react-native';
import axios from "axios";
import { BASE_URL } from "../../Config";
import moment from "moment";
import { resetAuth } from "../../Actions/AuthActions";
import { useDispatch } from "react-redux";
import { BLACK, DARK_GREY, PRIMARY, RED, SOFT_PEACH, VANiLLA_ICE, WHITE } from "../../Config/colors";
import LeftArrow from '../../Assets/Images/arrow-left.svg';

const PositionTrades = ({ props, route, navigation }) => {
    const dispatch = useDispatch()
    const { token } = route.params;
    const [isLoading, setLoading] = useState(false)
    const _onBack = () => navigation.goBack()
    const [trades, setTrades] = useState([])

    useEffect(() => {
        getTrades()
    }, [])

    const getTrades = () => {
        setLoading(true)
        axios.get(`${BASE_URL}/trade-list?instrument_token=${token}&created_date=DESC&trade_status=EXECUTED`)
            .then((res) => {
                setTrades(res.data)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    return (
        <>
            <SafeAreaView style={styles.container}>
                <View style={styles.header}>
                    <Pressable onPress={() => _onBack()} hitSlop={styles.hit}>
                        <LeftArrow style={styles.leftIcon} />
                    </Pressable>
                    <Text style={styles.tradesText}>Trades</Text>
                </View>
                {isLoading && trades.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color="#000" />
                </View>}
                {!isLoading && trades.length == 0 && <View style={styles.loaderView}>
                    <Text style={styles.errorMessage}>No Trades Found!</Text>
                </View>}
                {
                    !isLoading && trades.length > 0 &&
                    <FlatList
                        data={trades}
                        renderItem={({ item }) => <View style={[styles.card, { backgroundColor: item.order_action == 'SELL' ? VANiLLA_ICE : SOFT_PEACH }]}>
                            <View style={styles.cardTopView}>
                                <View style={styles.stockNameView}>
                                    <Text style={styles.stockName}>{item.trading_symbol}</Text>
                                </View>
                                <View>
                                    <Text style={styles.date}>{item.trade_date && moment(item.trade_date).format('DD/MM/YYYY HH:mm:ss')}</Text>
                                </View>
                            </View>
                            <View style={styles.cardBottomView}>
                                <View style={styles.stockNameView}>
                                    <Text style={[styles.type, { color: item.order_action == 'SELL' ? RED : '#1034A6' }]}>{item.order_action}</Text>
                                </View>
                                <View>
                                    <Text style={styles.price}>{item.price && (item.price.toFixed(2))}</Text>
                                </View>
                                <View>
                                    <Text style={styles.qty}>Qty: {item.quantity} ({item.lot && item.lot.toFixed(2)})</Text>
                                </View>
                            </View>
                        </View>}
                        keyExtractor={item => item.id}
                    />
                }
            </SafeAreaView>
        </>
    )
}

export default PositionTrades;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: WHITE
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    leftIcon: {
        marginLeft: 28,
        marginTop: 20
    },
    tradesText: {
        fontSize: 20,
        marginTop: 20,
        marginLeft: 15,
        color: PRIMARY,
        fontFamily: 'Roboto-Medium'
    },
    hit: {
        top: 20,
        bottom: 20,
        left: 50,
        right: 50
    },
    loaderView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    errorMessage: {
        color: BLACK,
        fontFamily: 'Roboto-Medium'
    },
    card: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 15,
        backgroundColor: SOFT_PEACH,
        borderRadius: 12
    },
    cardTopView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 7,
        marginLeft: 12,
        marginRight: 12
    },
    stockName: {
        color: BLACK,
        fontSize: 15,
        fontFamily: 'Roboto-Medium'
    },
    stockNameView: {
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    expiry: {
        color: DARK_GREY,
        fontSize: 12,
        fontFamily: 'Roboto-Bold'
    },
    cardBottomView: {
        marginTop: 8,
        marginBottom: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 12,
        marginRight: 12
    },
    price: {
        color: BLACK,
        fontSize: 16,
        fontFamily: 'Roboto-Bold'
    },
    qty: {
        color: BLACK,
        fontSize: 12,
        fontFamily: 'Roboto-Medium'
    },
    type: {
        color: RED,
        fontSize: 13,
        fontFamily: 'Roboto-Bold'
    },
    date: {
        color: DARK_GREY,
        fontSize: 12,
        fontFamily: 'Roboto-Medium'
    },
})