import React, { useEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { SafeAreaView, View, Text, Image, StatusBar, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

// SCREENS
import Login from '../Screens/Login/Login';
import MarketWatch from '../Screens/MarketWatch/MarketWatch';
import Orders from '../Screens/Orders/Orders';
import Trades from '../Screens/Trades/Trades';
import Positions from '../Screens/Positions/Positions';
import DrawerContent from '../Components/Drawer/DrawerContent';
import ChangePassword from '../Screens/ChangePassword/ChangePassword';
import Scripts from '../Screens/MarketWatch/Scripts';
import LoginUsers from '../Screens/LoginUsers/LoginUsers';
import { useDispatch } from 'react-redux';
import { resetAuth } from '../Actions/AuthActions';
import PositionTrades from '../Screens/Positions/PositionTrades';
import Setting from '../Screens/Setting/Setting';
import ThemeScreen from '../Screens/Setting/ThemeScreen';
import { useTheme } from '../Contexts/ThemeProvider';
import RejectionReport from '../Screens/RejectionReport/RejectionReport';
import EditScripts from '../Screens/MarketWatch/EditScripts';
import QuantityReport from '../Screens/QuantityReport/QuantityReport';
import Notification from '../Screens/Notification/Notification';
import LedgerReport from '../Screens/LedgerReport/LedgerReport';
import LedgerDetail from '../Screens/LedgerDetail/LedgerDetail';
import Margin from '../Screens/Margin/Margin';
import Home from '../Screens/Home/Home';
import { BLACK, PRIMARY, WHITE } from '../Config/colors';
import HomeIcon from '../Assets/Images/home.svg';
import MarketWatchIcon from '../Assets/Images/storefront.svg';
import OrderIcon from '../Assets/Images/home_repair_service.svg';
import TradesIcon from '../Assets/Images/earbuds.svg';
import PositionIcon from '../Assets/Images/more-520.svg';
import TextTicker from 'react-native-text-ticker';
import TermsAndCondition from '../Screens/TermsAndCondition/TermsAndCondition';
import FirstLoginPassword from '../Screens/FirstLoginPassword/FirstLoginPassword';

const Routes = () => {
    const { dark, colors } = useTheme();
    const loginSuccess = useSelector(state => state.auth.loginSuccess)
    const dispatch = useDispatch()

    const Stack = createStackNavigator();
    const Tab = createBottomTabNavigator();
    const Drawer = createDrawerNavigator();

    // useEffect(() => {
    //     dispatch(resetAuth())
    // }, [])

    const MyDrawer = () => {
        return (
            <Drawer.Navigator
                screenOptions={{ headerShown: false, allowFontScaling: false }}
                drawerContent={(props) => <DrawerContent {...props}
                />}>
                <Drawer.Screen name="HomeTabs" component={HomeTabs} />
            </Drawer.Navigator>
        )
    }

    const HomeTabs = () => {
        return (
            <>

                <Tab.Navigator
                    initialRouteName='Home'
                    screenOptions={{
                        headerShown: false, tabBarShowLabel: false,
                        tabBarStyle: styles.tabBar,
                    }}
                >
                    <Tab.Screen
                        name="Home"
                        component={Home}
                        options={{
                            tabBarIcon: ({ focused }) => {
                                return (
                                    <View style={styles.tabBarView}>
                                        <HomeIcon fill={focused ? PRIMARY : BLACK} height={25} width={25} />
                                        <Text
                                            style={[styles.tabName, { fontSize: focused ? 10 : 9, color: focused ? PRIMARY : BLACK }]}>
                                            Home
                                        </Text>
                                    </View>
                                )
                            }
                        }}
                    />
                    <Tab.Screen
                        name="MarketWatch"
                        component={MarketWatch}
                        options={{
                            tabBarIcon: ({ focused }) => {
                                return (
                                    <View style={styles.tabBarView}>
                                        <MarketWatchIcon fill={focused ? PRIMARY : BLACK} />
                                        <Text
                                            style={[styles.tabName, { fontSize: focused ? 10 : 9, color: focused ? PRIMARY : BLACK }]}>
                                            MarketWatch
                                        </Text>
                                    </View>
                                )
                            }
                        }}
                    />
                    <Tab.Screen
                        name="Orders"
                        component={Orders}
                        options={{
                            tabBarIcon: ({ focused }) => {
                                return (
                                    <View style={styles.tabBarView}>
                                        <OrderIcon fill={focused ? PRIMARY : BLACK} />
                                        <Text
                                            style={[styles.tabName, { fontSize: focused ? 10 : 9, color: focused ? PRIMARY : BLACK }]}>
                                            Orders
                                        </Text>
                                    </View>
                                )
                            }
                        }}
                    />
                    <Tab.Screen
                        name="Trades"
                        component={Trades}
                        options={{
                            tabBarIcon: ({ focused }) => {
                                return (
                                    <View style={styles.tabBarView}>
                                        <TradesIcon fill={focused ? PRIMARY : BLACK} />
                                        <Text
                                            style={[styles.tabName, { fontSize: focused ? 10 : 9, color: focused ? PRIMARY : BLACK }]}>
                                            Trades
                                        </Text>
                                    </View>
                                )
                            }
                        }}
                    />
                    <Tab.Screen
                        name="Positions"
                        component={Positions}
                        options={{
                            tabBarIcon: ({ focused }) => {
                                return (
                                    <View style={styles.tabBarView}>
                                        <PositionIcon fill={focused ? PRIMARY : BLACK} style={{ height: 24, width: 24 }} />
                                        <Text
                                            style={[styles.tabName, { fontSize: focused ? 10 : 9, color: focused ? PRIMARY : BLACK }]}>
                                            Positions
                                        </Text>
                                    </View>
                                )
                            },
                        }} />
                </Tab.Navigator>
            </>
        )
    }

    const MyStack = () => {
        return (
            <Stack.Navigator screenOptions={{
                headerShown: false,
                orientation: 'portrait',
            }}>
                {
                    loginSuccess ? (
                        <>
                            <Stack.Screen name="MyDrawer" component={MyDrawer} />
                            <Stack.Screen name="HomeTabs" component={HomeTabs} />
                            <Stack.Screen name="ChangePassword" component={ChangePassword} options={{ animationEnabled: false }} />
                            <Stack.Screen name="Scripts" component={Scripts} options={{ animationEnabled: false }} />
                            <Stack.Screen name="PositionTrades" component={PositionTrades} options={{ animationEnabled: false }} />
                            <Stack.Screen name="Setting" component={Setting} options={{ animationEnabled: false }} />
                            <Stack.Screen name="ThemeScreen" component={ThemeScreen} options={{ animationEnabled: false }} />
                            <Stack.Screen name="RejectionReport" component={RejectionReport} options={{ animationEnabled: false }} />
                            <Stack.Screen name="QuantityReport" component={QuantityReport} options={{ animationEnabled: false }} />
                            <Stack.Screen name="EditScripts" component={EditScripts} options={{ animationEnabled: false }} />
                            <Stack.Screen name="Notification" component={Notification} options={{ animationEnabled: false }} />
                            <Stack.Screen name="LedgerReport" component={LedgerReport} options={{ animationEnabled: false }} />
                            <Stack.Screen name="LedgerDetail" component={LedgerDetail} options={{ animationEnabled: false }} />
                            <Stack.Screen name="Margin" component={Margin} options={{ animationEnabled: false }} />
                        </>
                    )
                        :
                        (
                            <>
                                <Stack.Screen name="LoginUsers" component={LoginUsers} />
                                <Stack.Screen name="Login" component={Login} />
                                <Stack.Screen name="TermsAndCondition" component={TermsAndCondition} />
                                <Stack.Screen name="FirstLoginPassword" component={FirstLoginPassword} />
                            </>
                        )
                }

            </Stack.Navigator>
        )
    }
    return (
        <NavigationContainer>
            <StatusBar
                animated={true}
                backgroundColor="black"
            />
            <MyStack />
        </NavigationContainer>
    )
}

export default Routes;

const styles = StyleSheet.create({
    tabBar: {
        height: 70,
        elevation: 50,
        top: 1,
        backgroundColor: WHITE,
        shadowColor: 'black',
        shadowOpacity: 0.26,
        shadowOffset: { width: 4, height: 2 },
        shadowRadius: 10,
        marginTop: 1.5
    },
    tabBarView: {
        alignItems: 'center',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 20
    },
    tabName: {
        fontFamily: 'Roboto-Medium',
        lineHeight: 14,
    }
})