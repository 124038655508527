import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, Image, Pressable, TextInput, FlatList, ActivityIndicator, SafeAreaView } from 'react-native';
import { Width, getCustomDate } from "../../Utility/Utility";
// import backIcon from '../../Assets/Images/backArrow.png';
import backBlackIcon from '../../Assets/Images/back-arrow-black.png';
import axios from "axios";
import { BASE_URL } from "../../Config";
import { useTheme } from "../../Contexts/ThemeProvider";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Toast from 'react-native-toast-message';
import Spinner from 'react-native-loading-spinner-overlay';
import { resetAuth } from "../../Actions/AuthActions";
import { useDispatch } from "react-redux";
import { BLACK, GREY, LAVENDER_MIST, PRIMARY, SUVA_GREY, WHITE } from "../../Config/colors";
import BackIcon from '../../Assets/Images/arrow-left.svg';
import PlusIcon from '../../Assets/Images/small_add.svg';

const Scripts = (props) => {
    const dispatch = useDispatch()
    const { dark, colors } = useTheme();
    const [allScripts, setAllScripts] = useState([])
    const _onBack = () => props.navigation.goBack()
    const [isLoading, setLoading] = useState(false)
    const [selectedSegment, setSelectedSegment] = useState('NFO')
    const [searchText, setSearchText] = useState('')
    const [isSpinner, setIsSpinner] = useState(false)

    useEffect(() => {
        checkTab()
    }, [])


    const checkTab = async () => {
        await AsyncStorage.getItem('tab')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setSelectedSegment(data.currenTab)
                    getScripts(data.currenTab)
                } else {
                    getScripts('NFO')
                }
            })
            .catch(error => {
                getScripts('NFO')
            });
    }

    const getScripts = (value, key) => {
        setLoading(true)
        axios.get(`${BASE_URL}/script-list-mobile?exchange=${value}&keyword=${key ? key : ''}`)
            .then((res) => {
                setAllScripts(res.data)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const onAddScript = (data, index) => {
        setIsSpinner(true)
        axios.post(`${BASE_URL}/add-wishlist-mobile`, {
            exchange: data.exchange,
            instrument_token: data.instrument_token
        })
            .then((res) => {
                setIsSpinner(false)
                allScripts.splice(index, 1)
                setAllScripts([...allScripts])
                Toast.show({
                    type: 'success',
                    text1: `${data.name} ${data.expiry && getCustomDate(data.expiry)} added Successfully`,
                    visibilityTime: 1000
                });
            })
            .catch((e) => {
                setIsSpinner(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const onChangeTab = async (value) => {
        const data = JSON.stringify({ currenTab: value })
        await AsyncStorage.setItem('tab', data)
        setSelectedSegment(value)
        getScripts(value)
    }

    return (
        <>
            <Spinner
                visible={isSpinner}
                textContent={'Loading...'}
                textStyle={styles.spinnerTextStyle}
            />
            {/* <View style={[styles.container, { backgroundColor: colors.Primary }]}>
                <View style={[styles.header, { borderColor: colors.Secondary }]}>
                    <View>
                        <Pressable onPress={() => _onBack()}>
                            <Image source={dark ? backIcon : backBlackIcon} style={{ marginTop: 5, height: 18, width: 18 }} />
                        </Pressable>
                    </View>
                    <View style={styles.titleView}>
                        <Text style={[styles.headerTitle, { color: colors.Secondary }]}>Add Scripts</Text>
                    </View>
                </View>
                <View style={{ backgroundColor: '#b8e2ff', paddingBottom: 10 }}>
                    <View style={styles.segmentTabView}>
                        <Pressable style={[styles.segementTab, { backgroundColor: selectedSegment == 'NFO' ? '#0084df' : colors.Primary, borderColor: colors.Secondary }]} onPress={() => { onChangeTab('NFO') }}>
                            <Text style={[styles.tabText, { color: selectedSegment == 'NFO' ? 'white' : colors.Secondary }]}>NSE</Text>
                        </Pressable>
                        <Pressable style={[styles.segementTab, { backgroundColor: selectedSegment == 'MCX' ? '#0084df' : colors.Primary, borderColor: colors.Secondary }]} onPress={() => { onChangeTab('MCX') }}>
                            <Text style={[styles.tabText, { color: selectedSegment == 'MCX' ? 'white' : colors.Secondary }]}>MCX</Text>
                        </Pressable>
                    </View>
                </View>
                <View style={styles.headerIcon}>
                    <TextInput style={[styles.input, { color: colors.Secondary, backgroundColor: colors.color2 }]} placeholder='Search' placeholderTextColor={'#000'}  />
                </View>
                {isLoading && allScripts?.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color='#000' />
                </View>}
                {!isLoading && allScripts?.length == 0 && <View style={styles.loaderView}>
                    <Text style={[styles.headerTitle, { color: colors.Secondary }]}>No Scripts Found!</Text>
                </View>}
                {!isLoading && allScripts?.length > 0 &&
                    <FlatList
                        data={allScripts}
                        renderItem={({ item, index }) => <Pressable style={[styles.item, { borderColor: colors.Secondary }]} onPress={() => onAddScript(item, index)} key={new Date()}>
                            <View style={styles.nameView}>
                                <Text style={[styles.tradeName, { color: colors.Secondary }]}>{item.name + getCustomDate(item.expiry)}</Text>
                                <View style={styles.addButton} >
                                    <Text style={styles.addButtonText}>+</Text>
                                </View>
                            </View>
                            <View style={styles.priceView}>
                                <Text style={[styles.avgPrice, { color: colors.Secondary }]}>Expiry : {item.expiry}</Text>
                            </View>
                        </Pressable>}
                        keyExtractor={item => item.id}
                    />
                }
            </View> */}
            <SafeAreaView style={styles.container}>
                <View style={styles.header}>
                    <Pressable hitSlop={styles.hit} onPress={() => _onBack()}>
                        <BackIcon />
                    </Pressable>
                    <Text style={styles.headerTitle}>Add Script</Text>
                </View>
                <View style={styles.searchBox}>
                    <TextInput
                        placeholder="Search script"
                        placeholderTextColor={BLACK}
                        style={styles.search}
                        value={searchText}
                        onChangeText={text => { setSearchText(text), getScripts(selectedSegment, text) }} />
                </View>
                <View style={styles.tagView}>
                    <Pressable style={[styles.tag, {
                        backgroundColor: selectedSegment == "NFO" ? PRIMARY : LAVENDER_MIST,
                        marginLeft: 20
                    }]} onPress={() => onChangeTab('NFO')} hitSlop={styles.hit}>
                        <Text style={[styles.tagText, {
                            color: selectedSegment == "NFO" ? WHITE : PRIMARY,
                        }]}>NSE</Text>
                    </Pressable>
                    <Pressable style={[styles.tag, {
                        backgroundColor: selectedSegment == "MCX" ? PRIMARY : LAVENDER_MIST,
                        marginLeft: 17
                    }]} onPress={() => onChangeTab('MCX')} hitSlop={styles.hit}>
                        <Text style={[styles.tagText, {
                            color: selectedSegment == "MCX" ? WHITE : PRIMARY,
                        }]}>MCX</Text>
                    </Pressable>
                </View>
                {isLoading && allScripts?.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color={BLACK} />
                </View>}
                {!isLoading && allScripts?.length == 0 && <View style={styles.loaderView}>
                    <Text style={styles.errorMessage}>No Scripts Found!</Text>
                </View>}
                {!isLoading && allScripts?.length > 0 &&
                    <FlatList
                        data={allScripts}
                        renderItem={({ item, index }) =>
                            <Pressable style={[styles.card, { marginTop: index == 0 ? 20 : 10 }]} onPress={() => onAddScript(item, index)} key={index}>
                                <View>
                                    <Text style={styles.name}>{item.name + getCustomDate(item.expiry)}</Text>
                                    <Text style={styles.expiry}>Expiry : {item.expiry}</Text>
                                </View>
                                <View>
                                    <View style={styles.addBackground}>
                                        <PlusIcon style={styles.plusIcon} />
                                    </View>
                                </View>
                            </Pressable>
                        }
                        keyExtractor={item => item.id}
                    />
                }
            </SafeAreaView>
        </>
    )
}

export default Scripts;

const styles = StyleSheet.create({
    // container: {
    //     flex: 1,
    // },
    // header: {
    //     paddingLeft: 25,
    //     marginTop: 10,
    //     borderBottomWidth: 1,
    //     flexDirection: 'row',
    //     paddingRight: 25,
    //     height: 40
    // },
    // headerTitle: {
    //     fontSize: 16,
    // },
    // titleView: {
    //     alignItems: 'center',
    //     width: '90%'
    // },
    // item: {
    //     borderWidth: 1,
    //     marginTop: 3,
    //     borderRadius: 5,
    //     padding: 10,
    //     margin: 8
    // },
    // tradeName: {
    //     fontSize: 18,
    //     fontWeight: '700'
    // },
    // tradeQty: {
    //     color: 'green',
    //     fontSize: 18
    // },
    // nameView: {
    //     flexDirection: 'row',
    //     justifyContent: 'space-between'
    // },
    // priceView: {
    //     flexDirection: 'row',
    //     justifyContent: 'space-between',
    //     marginTop: 5
    // },
    // price: {
    //     color: 'green',
    // },
    // addButton: {
    //     backgroundColor: 'green',
    //     height: 25,
    //     width: 25,
    //     borderRadius: 5,
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     marginRight: 10
    // },
    // addButtonText: {
    //     color: 'white'
    // },
    // loaderView: {
    //     flex: 1,
    //     justifyContent: 'center',
    //     alignItems: 'center'
    // },
    // segmentTabView: {
    //     flexDirection: 'row',
    //     marginLeft: 22,
    //     marginRight: 22,
    //     marginTop: 10,
    // },
    // tabText: {
    //     fontWeight: '700'
    // },
    // segementTab: {
    //     width: '50%',
    //     alignItems: 'center',
    //     borderWidth: 1,

    //     padding: 3,
    //     borderRadius: 5,
    //     marginLeft: 5
    // },
    // headerIcon: {
    //     flexDirection: 'row',
    //     justifyContent: 'center',
    //     marginTop: 10
    // },
    // input: {
    //     borderRadius: 12,
    //     width: Width * 0.9,
    //     paddingLeft: 15,
    //     bottom: 6,
    //     height: 50
    // },
    container: {
        flex: 1,
        backgroundColor: WHITE
    },
    header: {
        flexDirection: 'row',
        marginTop: 20,
        marginLeft: 20,
        alignItems: 'center'
    },
    headerTitle: {
        marginLeft: 20,
        color: PRIMARY,
        fontSize: 20,
        fontFamily: 'Roboto-Medium'
    },
    hit: {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10
    },
    searchBox: {
        backgroundColor: GREY,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 30,
        borderRadius: 53,
        flexDirection: 'row',
        height: 45
    },
    search: {
        paddingLeft: 28,
        width: '100%'
    },
    tagView: {
        flexDirection: 'row'
    },
    tag: {
        borderRadius: 35,
        marginTop: 25
    },
    tagText: {
        fontFamily: 'Roboto-Medium',
        fontSize: 11,
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 16,
        paddingLeft: 16
    },
    loaderView: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    errorMessage: {
        fontSize: 14,
        fontFamily: 'Roboto-Bold'
    },
    card: {
        borderWidth: 1,
        borderColor: GREY,
        marginLeft: 20,
        marginRight: 20,
        borderRadius: 12,
        shadowOpacity: 0.26,
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 10,
        elevation: 4,
        backgroundColor: WHITE,
        shadowColor: 'rgba(0, 0, 0, 0.25)',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    name: {
        color: BLACK,
        fontSize: 15,
        fontFamily: 'Roboto-Medium',
        marginTop: 8,
        marginLeft: 15
    },
    expiry: {
        color: SUVA_GREY,
        fontSize: 12,
        fontFamily: 'Roboto-Regular',
        marginTop: 5,
        marginLeft: 15,
        marginBottom: 5
    },
    addBackground: {
        backgroundColor: PRIMARY,
        marginRight: 15,
        borderRadius: 7
    },
    plusIcon: {
        marginTop: 2,
        marginBottom: 2,
        marginLeft: 5,
        marginRight: 5,
    }
})