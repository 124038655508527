import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Text, Image, Pressable, TextInput, FlatList, ActivityIndicator, SafeAreaView } from 'react-native';
import { useTheme } from '../../Contexts/ThemeProvider';
import backIcon from '../../Assets/Images/backArrow.png';
import backBlackIcon from '../../Assets/Images/back-arrow-black.png';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { Height } from '../../Utility/Utility';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';
import LeftArrow from '../../Assets/Images/arrow-left.svg';
import { BLACK, LAVENDER_MIST, PRIMARY, WHITE } from '../../Config/colors';

const QuantityReport = (props) => {
    const dispatch = useDispatch()
    const { dark, colors } = useTheme();
    const _onBack = () => props.navigation.goBack()
    const [reportData, setReportData] = useState([])
    const userId = useRef('')
    const [selectedSegment, setSelectedSegment] = useState('NFO')
    const [isLoading, setLoading] = useState(false)
    const [selectedTag, setSelectedTag] = useState('NSE')

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        try {
            const jsonValue = await AsyncStorage.getItem('user_detail')
            const data = jsonValue != null ? JSON.parse(jsonValue) : null;
            userId.current = data.id
            checkTab()
        } catch (e) {
            // error reading value
        }
    }

    const checkTab = async () => {
        await AsyncStorage.getItem('tab')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setSelectedSegment(data.currenTab)
                    getReport(data.currenTab)
                } else {
                    getReport('NFO')
                }
            })
            .catch(error => {
                getReport('NFO')
            });
    }

    const getReport = (value) => {
        setLoading(true)
        axios.get(`${BASE_URL}/quantity-setting-list?exchange=${value}&user_id=${userId.current}`)
            .then((res) => {
                setLoading(false)
                setReportData(res.data)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const onChangeTab = async (value) => {
        const data = JSON.stringify({ currenTab: value })
        await AsyncStorage.setItem('tab', data)
        setReportData([])
        setSelectedSegment(value)
        getReport(value)
    }

    return (
        <>
            {/* <View style={[styles.container, { backgroundColor: colors.Primary }]}>
                <View style={[styles.header, { borderColor: colors.Secondary }]}>
                    <View>
                        <Pressable onPress={() => _onBack()}>
                            <Image source={dark ? backIcon : backBlackIcon} style={{ marginTop: 5, height: 18, width: 18 }} />
                        </Pressable>
                    </View>
                    <View style={styles.titleView}>
                        <Text style={[styles.headerTitle, { color: colors.Secondary }]}>Quantity Setting</Text>
                    </View>
                </View>
                <View style={{ backgroundColor: '#b8e2ff', paddingBottom: 10 }}>
                    <View style={styles.segmentTabView}>
                        <Pressable style={[styles.segementTab, { backgroundColor: selectedSegment == 'NFO' ? '#0084df' : colors.Primary, borderColor: colors.Secondary }]} onPress={() => { onChangeTab('NFO') }}>
                            <Text style={[styles.tabText, { color: selectedSegment == 'NFO' ? colors.Primary : colors.Secondary }]}>NSE</Text>
                        </Pressable>
                        <Pressable style={[styles.segementTab, { backgroundColor: selectedSegment == 'MCX' ? '#0084df' : colors.Primary, borderColor: colors.Secondary }]} onPress={() => { onChangeTab('MCX') }}>
                            <Text style={[styles.tabText, { color: selectedSegment == 'MCX' ? colors.Primary : colors.Secondary }]}>MCX</Text>
                        </Pressable>
                    </View>
                </View>
                {
                    reportData.length > 0 && <FlatList
                        data={reportData}
                        renderItem={({ item }) => <View style={{ padding: 10, borderBottomWidth: 1, borderBottomColor: '#D3D3D3' }} key={new Date()}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontWeight: 'bold', width: '50%' }}>{item.instrument_token}</Text>
                                <View style={{ flexDirection: 'row', width: '50%', justifyContent: 'space-between' }}>
                                    <Text style={{ fontWeight: '500' }}>Max: {item.max_order}</Text>
                                    <Text style={{ fontWeight: '500' }}>{item.position_limit}</Text>
                                </View>
                            </View>
                        </View>}
                        keyExtractor={item => item.id}
                    />
                }
                {isLoading && reportData.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color='#000' />
                </View>}
                {!isLoading && reportData.length == 0 && <View style={styles.loaderView}>
                    <Text style={{ color: colors.P }}>No Quantity Setting Found!</Text>
                </View>}
            </View> */}
            <SafeAreaView style={styles.container}>
                <View style={styles.header}>
                    <Pressable onPress={() => props.navigation.goBack()} hitSlop={styles.hit}>
                        <LeftArrow style={styles.leftIcon} />
                    </Pressable>
                    <Text style={styles.quantitySettingText}>Quantity Setting</Text>
                </View>
                <View style={styles.tagView}>
                    <Pressable style={[styles.tag, {
                        backgroundColor: selectedSegment == "NFO" ? PRIMARY : LAVENDER_MIST,
                        marginLeft: 20
                    }]} onPress={() => onChangeTab('NFO')} hitSlop={styles.hit}>
                        <Text style={[styles.tagText, {
                            color: selectedSegment == "NFO" ? WHITE : PRIMARY,
                        }]}>NSE</Text>
                    </Pressable>
                    <Pressable style={[styles.tag, {
                        backgroundColor: selectedSegment == "MCX" ? PRIMARY : LAVENDER_MIST,
                        marginLeft: 17
                    }]} onPress={() => onChangeTab('MCX')} hitSlop={styles.hit}>
                        <Text style={[styles.tagText, {
                            color: selectedSegment == "MCX" ? WHITE : PRIMARY,
                        }]}>MCX</Text>
                    </Pressable>
                </View>
                {
                    reportData.length > 0 && <FlatList
                        data={reportData}
                        renderItem={({ item, index }) => <View style={{ padding: 10, borderBottomWidth: 1, borderBottomColor: '#D3D3D3', marginTop: index == 0 ? 20 : 0 }} key={new Date()}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontWeight: 'bold', width: '50%' }}>{item.instrument_token}</Text>
                                <View style={{ flexDirection: 'row', width: '50%', justifyContent: 'space-between' }}>
                                    <Text style={{ fontWeight: '500' }}>Max: {item.max_order}</Text>
                                    <Text style={{ fontWeight: '500' }}>{item.position_limit}</Text>
                                </View>
                            </View>
                        </View>}
                        keyExtractor={item => item.id}
                    />
                }
                {isLoading && reportData.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color={BLACK} />
                </View>}
                {!isLoading && reportData.length == 0 && <View style={styles.loaderView}>
                    <Text style={styles.errorMessage}>No Quantity Setting Found!</Text>
                </View>}
            </SafeAreaView>
        </>
    )
}

export default QuantityReport;

const styles = StyleSheet.create({
    // container: {
    //     flex: 1
    // },
    // header: {
    //     paddingLeft: 25,
    //     marginTop: 10,
    //     borderBottomWidth: 1,
    //     flexDirection: 'row',
    //     paddingRight: 25,
    //     height: 40
    // },
    // titleView: {
    //     alignItems: 'center',
    //     width: '90%'
    // },
    // headerTitle: {
    //     fontSize: 16,
    // },
    // verticalLine: {
    //     borderRightWidth: 1
    // },
    // segmentTabView: {
    //     flexDirection: 'row',
    //     marginLeft: 22,
    //     marginRight: 22,
    //     marginTop: 10,
    // },
    // segementTab: {
    //     width: '50%',
    //     alignItems: 'center',
    //     borderWidth: 1,
    //     padding: 3,
    //     borderRadius: 5,
    //     marginLeft: 5
    // },
    // loaderView: {
    //     flex: 1,
    //     justifyContent: 'center',
    //     alignItems: 'center'
    // },
    container: {
        flex: 1,
        backgroundColor: WHITE
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    leftIcon: {
        marginLeft: 28,
        marginTop: 20
    },
    quantitySettingText: {
        fontSize: 20,
        marginTop: 20,
        marginLeft: 15,
        color: PRIMARY,
        fontFamily: 'Roboto-Medium'
    },
    hit: {
        top: 20,
        bottom: 20,
        left: 50,
        right: 50
    },
    tagView: {
        flexDirection: 'row'
    },
    tag: {
        borderRadius: 35,
        marginTop: 25
    },
    tagText: {
        fontFamily: 'Roboto-Medium',
        fontSize: 11,
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 16,
        paddingLeft: 16
    },
    loaderView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    errorMessage: {
        fontFamily: 'Roboto-Medium'
    }
})