import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Text, Pressable, TextInput, SafeAreaView } from 'react-native';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Toast from 'react-native-toast-message';
import Spinner from 'react-native-loading-spinner-overlay';
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';
import LeftArrow from '../../Assets/Images/arrow-left.svg';
import { BLACK, PRIMARY, WHITE } from '../../Config/colors';

const ChangePassword = (props) => {
    const dispatch = useDispatch()
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [isSpinner, setIsSpinner] = useState(false)
    const userId = useRef('')

    useEffect(() => {
        getUserDetail()
    }, [])

    const getUserDetail = async () => {
        try {
            const jsonValue = await AsyncStorage.getItem('user_detail')
            const data = jsonValue != null ? JSON.parse(jsonValue) : null;
            userId.current = data.id
        } catch (e) {
            // error reading value
        }
    }

    const onChangePassword = () => {
        if (oldPassword == '') {
            Toast.show({
                type: 'error',
                text1: 'Please enter old password',
                visibilityTime: 1000
            });
        } else if (newPassword == '') {
            Toast.show({
                type: 'error',
                text1: 'Please enter new password',
                visibilityTime: 1000
            });
        } else if (confirmNewPassword == '') {
            Toast.show({
                type: 'error',
                text1: 'Please enter confirm new password',
                visibilityTime: 1000
            });
        } else if (newPassword != confirmNewPassword) {
            Toast.show({
                type: 'error',
                text1: 'New password and confirm new password does not match',
                visibilityTime: 1000
            });
        } else {
            setIsSpinner(true)
            axios.put(`${BASE_URL}/reset/password`, {
                id: userId.current,
                new_password: newPassword,
                old_password: oldPassword
            })
                .then((res) => {
                    setIsSpinner(false)
                    Toast.show({
                        type: 'success',
                        text1: res.data?.message,
                        visibilityTime: 1000
                    });
                })
                .catch((e) => {
                    setIsSpinner(false)
                    if (e.response.status == 401) {
                        dispatch(resetAuth())
                    }
                })
        }
    }


    return (
        <>
            <Spinner
                visible={isSpinner}
                textContent={'Loading...'}
                textStyle={styles.spinnerTextStyle}
            />
            <SafeAreaView style={styles.container}>
                <View style={styles.header}>
                    <Pressable onPress={() => props.navigation.goBack()} hitSlop={styles.hit}>
                        <LeftArrow style={styles.leftIcon} />
                    </Pressable>
                    <Text style={styles.changePasswordText}>Change Password</Text>
                </View>
                <Text style={[styles.label, { marginTop: 30 }]}>Password</Text>
                <TextInput style={styles.input} onChangeText={text => setOldPassword(text)} value={oldPassword} />
                <Text style={[styles.label, { marginTop: 15 }]}>New Password</Text>
                <TextInput style={styles.input} onChangeText={text => setNewPassword(text)} value={newPassword} />
                <Text style={[styles.label, { marginTop: 15 }]}>Confirm New Password</Text>
                <TextInput style={styles.input} onChangeText={text => setConfirmNewPassword(text)} value={confirmNewPassword} />
                <Pressable style={styles.saveButton} onPress={() => onChangePassword()}>
                    <Text style={styles.saveButtonText}>Save</Text>
                </Pressable>
            </SafeAreaView>
        </>
    )
}

export default ChangePassword;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: WHITE
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    leftIcon: {
        marginLeft: 28,
        marginTop: 20
    },
    changePasswordText: {
        fontSize: 20,
        marginTop: 20,
        marginLeft: 15,
        color: PRIMARY,
        fontFamily: 'Roboto-Medium'
    },
    hit: {
        top: 20,
        bottom: 20,
        left: 50,
        right: 50
    },
    label: {
        color: BLACK,
        marginLeft: 30,
        fontFamily: 'Roboto-Medium',
        fontSize: 14
    },
    input: {
        borderColor: PRIMARY,
        borderWidth: 1,
        borderRadius: 10,
        width: '85%',
        marginLeft: 30,
        marginTop: 6,
        paddingLeft: 15,
        color: BLACK,
        height: 42
    },
    saveButton: {
        backgroundColor: PRIMARY,
        alignSelf: 'center',
        marginTop: 40,
        borderRadius: 16
    },
    saveButtonText: {
        color: WHITE,
        fontSize: 20,
        fontFamily: 'Roboto-Bold',
        marginTop: 10,
        marginBottom: 9,
        marginLeft: 40,
        marginRight: 40
    }
})