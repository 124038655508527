import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Text, Pressable, FlatList, ActivityIndicator, SafeAreaView } from 'react-native';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import moment from 'moment';
import { useIsFocused } from "@react-navigation/native";
import { RefreshControl } from 'react-native-web-refresh-control'
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';
import LeftArrow from '../../Assets/Images/arrow-left.svg';
import { BLACK, DARK_GREY, GREEN, GREY, PRIMARY, RED, WHITE } from '../../Config/colors';

const LedgerReport = (props) => {
    const dispatch = useDispatch()
    const userId = useRef('')
    const [reportData, setReportData] = useState([])
    const [credit, setCredit] = useState(0)
    const [debit, setDebit] = useState(0)
    const isFocused = useIsFocused();
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        getUserData()

    }, [isFocused])

    const getUserData = () => {
        setLoading(true)
        axios.get(`${BASE_URL}/me`)
            .then((res) => {
                userId.current = res.data.id
                getReport(res?.data?.id)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const getReport = (id) => {
        axios.get(`${BASE_URL}/user-settlement-list-app?id=${userId.current}`)
            .then((res) => {
                var credit = 0;
                var debit = 0;
                if (res.data && res.data.length > 0) {
                    for (var i in res.data) {
                        if (i == 0) {
                            res.data[i].balance = 0;
                        } else {
                            res.data[i].balance = res.data[i - 1].balance;
                        }

                        res.data[i].balance += res.data[i].debit ? res.data[i].debit : 0;
                        debit += res.data[i].debit ? res.data[i].debit : 0;
                        credit += res.data[i].credit ? res.data[i].credit : 0;
                        res.data[i].balance += res.data[i].credit
                            ? res.data[i].credit
                            : 0;
                    }
                }
                setDebit(debit)
                setCredit(credit)
                setReportData(res.data)
                setLoading(false)
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }



    return (
        <>
            <SafeAreaView style={styles.container}>
                <View style={styles.header}>
                    <Pressable onPress={() => props.navigation.goBack()} hitSlop={styles.hit}>
                        <LeftArrow style={styles.leftIcon} />
                    </Pressable>
                    <Text style={styles.ledgerReportText}>Ledger Report</Text>
                </View>
                <View style={styles.topView}>
                    <Text style={[styles.amount, { color: (Number(debit) + Number(credit)) > 0 ? GREEN : RED }]}>{(Number(credit) + Number(debit)).toFixed(2)}</Text>
                </View>
                {!isLoading && reportData.length > 0 &&
                    <FlatList
                        data={reportData}
                        renderItem={({ item, index }) => <Pressable style={[styles.item, { marginBottom: index == reportData.length - 1 ? 15 : 0, marginTop: index == 0 ? 20 : 8 }]} onPress={() => props.navigation.navigate('LedgerDetail', { data: item })}>
                            <View>
                                <Text style={styles.date}>{item.remark} MCX-NSE</Text>
                                <Text style={styles.actualDate}>{item.created_at && moment(item.created_at).format('DD-MM-YYYY')}</Text>
                            </View>
                            <Text style={[styles.itemAmount, { color: Number(item.debit) < 0 ? RED : Number(item.credit) > 0 ? GREEN : BLACK }]}>{item.debit != 0 ? item.debit.toFixed(2) : item.credit.toFixed(2)}</Text>
                        </Pressable>}
                        keyExtractor={item => item.id}
                        refreshControl={
                            <RefreshControl refreshing={isLoading} onRefresh={() => { getUserData() }} />
                        }
                    />
                }
                {
                    !isLoading && reportData?.length == 0 &&
                    <View style={styles.loaderView}>
                        <Text style={styles.errorMessage}>No Data Found!</Text>
                    </View>
                }
                {isLoading && reportData.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color={BLACK} />
                </View>}
            </SafeAreaView>
        </>
    )
}

export default LedgerReport;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: WHITE
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    leftIcon: {
        marginLeft: 28,
        marginTop: 20
    },
    ledgerReportText: {
        fontSize: 20,
        marginTop: 20,
        marginLeft: 15,
        color: PRIMARY,
        fontFamily: 'Roboto-Medium'
    },
    hit: {
        top: 20,
        bottom: 20,
        left: 50,
        right: 50
    },
    topView: {
        backgroundColor: PRIMARY,
        marginLeft: 30,
        marginRight: 30,
        marginTop: 35,
        borderRadius: 9
    },
    amount: {
        color: GREEN,
        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'Roboto-Medium',
        marginTop: 16,
        marginBottom: 15
    },
    item: {
        borderWidth: 1,
        borderColor: GREY,
        borderRadius: 12,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 8,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    date: {
        color: BLACK,
        fontSize: 14,
        fontFamily: 'Roboto-Medium',
        marginTop: 11,
        marginLeft: 20
    },
    actualDate: {
        color: DARK_GREY,
        marginLeft: 20,
        marginTop: 7,
        marginBottom: 10
    },
    itemAmount: {
        color: GREEN,
        marginRight: 10,
        fontSize: 16,
        fontFamily: 'Roboto-Bold'
    },
    loaderView: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    errorMessage: {
        color: BLACK,
        fontFamily: 'Roboto-Medium'
    }
})